import React, { useState } from 'react'

const NotInterested = ({ setIndex, happenedIndex, updateDialoutcomes, handleFinish, handleOpen3 }) => {
    const [notInterestedReason, setNotInterestedReason] = useState("");
    const [finishClicked, setFinishClicked] = useState(false);
    const [isPositioned, setIsPositioned] = useState(false)
    setTimeout(() => setIsPositioned(true), 1000)

    return (
        <>	<div
            class="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-10 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
            <h4 class="text-center text-lg font-semibold xl:text-xl">
                Why is the prospect not interested?
            </h4>

            <div class="cursor-pointer mt-6 flex flex-col items-center justify-center gap-4">
                <a
                onClick={() => setNotInterestedReason("No budget")}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === "No budget" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                    }}>
                    No budget
                </a>
                <a
                onClick={() => setNotInterestedReason("Uses competitor")}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === "Uses competitor" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                    }}>
                    Uses competitor
                </a>
                <a
                onClick={() => setNotInterestedReason("Not a good time")}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === "Not a good time" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                    }}>
                    Not a good time
                </a>
                <a
                onClick={() => setNotInterestedReason("No need for our service")}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === "No need for our service" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                    }}>
                    No need for our service
                </a>
            </div>
        </div>

            <div class="cursor-pointer flex flex-wrap items-center justify-between gap-2">
                <a
                    onClick={() => setIndex(1)}
                    class="inline-flex items-center gap-x-2 text-gray-800 transition hover:text-secondary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        class="h-4 w-4"
                        viewBox="0 0 24 24">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Go Back
                </a>
                <button
                    onClick={() => { updateDialoutcomes(happenedIndex); setFinishClicked(true); handleFinish(); }}
                    class={`inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg ${notInterestedReason === "" ? "hidden" : ""}`}
                    disabled={finishClicked}>
                    Finish
                </button>
                <span
                    onClick={handleOpen3}
                    data-hs-overlay="#call-notes-modal"
                    className="cursor-pointer rounded-lg bg-transparent-1 p-1 text-primary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="h-6 w-6"
                    >
                        <path
                            d="M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z" />
                    </svg>
                </span>
            </div>
        </>
    )
}

export default NotInterested
