import { toast } from 'react-toastify';

import Logo from '../../assets/header/login/img/logo.png';
import LogoToX from '../../assets/header/login/img/logo@2x.png';
import LogoTo from '../../assets/header/login/img/logo-2.png';
import LogoToXX from '../../assets/header/login/img/logo-2@2x.png';

import './css/app.css';
import './css/vendor.css';

export const Register = () => {
  const handleRegister = event => {
    // Match passwords
    if (event.target.password.value !== event.target.confirm_password.value) {
      toast.info('Passwords do not match', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    // Handle register logic
    event.preventDefault();
    // Add your register logic here
    fetch('/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: event.target.email.value,
        password: event.target.password.value,
        confirm_password: event.target.confirm_password.value,
      }),
    }).then(response => {
      if (response.status === 200) {
        window.location.href = '/login';
      } else {
        toast.info('Registration failed', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <div>
      {/*// <!-- HEADER :: START-->*/}
      <nav className="header" id="header">
        <div className="c-grid-fluid">
          <div className="header__wrapper">
            <div>
              <div className="logo logo--header">
                <a className="logo__link" href="/">
                  <img className="logo__image" src={Logo} alt="Logotype" />
                </a>
              </div>
            </div>
            {/* <div>
                            <nav class="header__nav"><a href="#">IQ Accelerate</a><a href="#">IQ Boost</a><a href="#">IQ
                                Scale</a><a href="#">Pricing</a><a href="#">About</a></nav>
                        </div>
                        <div>
                            <div class="header__btn c-btn__wrapper"><a class="c-btn" href="#">Book a Demo</a></div>
                            <div class="hamburger hamburger--squeeze" role="button" hamburger-js="hamburger-js">
                                <div class="hamburger-box">
                                    <div class="hamburger-inner"></div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </nav>
      {/*// <!-- HEADER :: END-->*/}
      <main className="main main--start p-sign-up">
        {/*// <!-- SECTION :: START-->*/}
        <section className="sign" id="sign">
          <div className="c-grid">
            <div className="section__wrapper">
              <div className="c-section" data-align="center">
                <div className="c-section__head">
                  <div className="logo logo--header">
                    <a className="logo__link" href="/">
                      <img
                        className="logo__image"
                        src={LogoTo}
                        alt="Logotype"
                      />
                    </a>
                  </div>
                </div>
                <div className="c-section__body">
                  <h5 className="c-section__title">Sign up</h5>
                  <p className="c-section__desc">
                    Fill in your details below and continue signing up
                  </p>
                  <form
                    className="c-form"
                    action=""
                    method=""
                    autoComplete="off"
                    onSubmit={handleRegister}
                  >
                    <div className="c-form__group">
                      <div>
                        <div className="c-form__field">
                          <label className="c-form__label" htmlFor="first_name">
                            First name*
                          </label>
                          <input
                            className="c-form__input"
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="Type your first name..."
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <div className="c-form__field">
                          <label className="c-form__label" htmlFor="last_name">
                            Last name*
                          </label>
                          <input
                            className="c-form__input"
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Type your last name..."
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="c-form__input"
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter your email address"
                        required
                        autoComplete="username"
                      />
                    </div>
                    <div className="c-form__group">
                      <div>
                        <div className="c-form__field">
                          <label className="c-form__label" htmlFor="password">
                            Password
                          </label>
                          <input
                            className="c-form__input"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter your password..."
                            required
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="c-form__field">
                          <label
                            className="c-form__label"
                            htmlFor="confirm_password"
                          >
                            Confirm password*
                          </label>
                          <input
                            className="c-form__input"
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            placeholder="Retype your password..."
                            required
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="c-form__field c-form__field--btn">
                      <div className="c-btn__wrapper">
                        <input
                          className="c-btn"
                          type={'submit'}
                          data-btn-color="blue"
                          data-btn-size="full"
                          data-btn-fw="600"
                          data-btn-round="half"
                          value={'Create account'}
                        />
                      </div>
                    </div>
                    <p className="sign__link">
                      Already have an account? <a href="/login">Sign in</a>
                    </p>
                    <div className="c-form__field c-form__field--info">
                      <p className="c-section__desc" style={{ padding: 0 }}>
                        By clicking “Create account” you agree to our{' '}
                        <a href="#">Terms of Service</a> and{' '}
                        <a href="#">Privacy Policy</a>
                      </p>
                    </div>
                  </form>
                </div>
                <div className="c-section__footer">
                  <p className="c-section__info">
                    Having trouble? Contact us at{' '}
                    <a href="#">support@prospectiq.ai</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*// <!-- SECTION :: END-->*/}
      </main>
      {/*// <!-- FOOTER :: START-->*/}
      <footer className="footer" id="footer">
        <div className="c-grid">
          <div className="footer__wrapper"></div>
        </div>
      </footer>
      {/*// <!-- FOOTER :: END-->*/}
    </div>
  );
};
