import moment from 'moment';

import { DATE_TIME_FORMAT } from './date-time-format';

export const DEFAULT_MIN_APPOINTMENT_TIME = '8:00 AM';
export const DEFAULT_MAX_APPOINTMENT_TIME = '19:00 PM';

export const getInitialAppointmentTime = isToday => {
  if (!isToday) return DEFAULT_MIN_APPOINTMENT_TIME;

  const appointmentTime = moment();
  const minutes = appointmentTime.minutes();

  if (minutes < 30) {
    appointmentTime.minutes(30);
  }

  if (minutes >= 30) {
    appointmentTime.minutes(0);
    appointmentTime.add(1, 'h');
  }

  const formattedAppointmentTime = appointmentTime.format(
    DATE_TIME_FORMAT.h_mm_A,
  );

  const isBefore = moment(
    formattedAppointmentTime,
    DATE_TIME_FORMAT.h_mm_A,
  ).isBefore(moment(DEFAULT_MIN_APPOINTMENT_TIME, DATE_TIME_FORMAT.h_mm_A));

  const isAfter = moment(
    formattedAppointmentTime,
    DATE_TIME_FORMAT.h_mm_A,
  ).isAfter(moment(DEFAULT_MAX_APPOINTMENT_TIME, DATE_TIME_FORMAT.h_mm_A));

  if (isAfter || isBefore) return;

  return formattedAppointmentTime;
};
