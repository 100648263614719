import * as yup from 'yup';

import { ERROR_MESSAGES } from './error-messages';

export const VALIDATION_SCHEMAS = {
  EDIT_CONTACT: yup.object().shape({
    company: yup
      .string()
      .trim()
      .max(400, ERROR_MESSAGES.MAX_LENGTH(400))
      .required(ERROR_MESSAGES.REQUIRED),
    contact: yup.string().trim().max(255, ERROR_MESSAGES.MAX_LENGTH(255)),
    title: yup.string().trim().max(255, ERROR_MESSAGES.MAX_LENGTH(255)),
    industry: yup.string().trim().max(40, ERROR_MESSAGES.MAX_LENGTH(40)),
    time: yup.string().required(ERROR_MESSAGES.REQUIRED),
    address: yup.string().trim().max(255, ERROR_MESSAGES.MAX_LENGTH(255)),
    city: yup.string().trim().max(40, ERROR_MESSAGES.MAX_LENGTH(40)),
    state: yup.string().trim().max(40, ERROR_MESSAGES.MAX_LENGTH(40)),
    website: yup.string().trim().max(400, ERROR_MESSAGES.MAX_LENGTH(400)),
    email: yup
      .string()
      .trim()
      .max(255, ERROR_MESSAGES.MAX_LENGTH(255))
      .email(ERROR_MESSAGES.EMAIL_NOT_VALID),
    linkedin: yup.string().trim().max(255, ERROR_MESSAGES.MAX_LENGTH(255)),
  }),
  CALL_NOTES: yup.object().shape({
    callNotes: yup.string().trim().max(500, ERROR_MESSAGES.MAX_LENGTH(500)),
  }),
  LOGIN: yup.object().shape({
    username: yup.string().trim().required(ERROR_MESSAGES.REQUIRED),
    password: yup.string().trim().required(ERROR_MESSAGES.REQUIRED),
  }),
  FORGOT_PASSWORD: yup.object().shape({
    email: yup.string().trim().required(ERROR_MESSAGES.REQUIRED),
  }),
  SCRIPT: yup.object().shape({
    ScriptName: yup
      .string()
      .trim()
      .max(255, ERROR_MESSAGES.MAX_LENGTH(255))
      .required(ERROR_MESSAGES.REQUIRED),
    ScriptDescription: yup
      .string()
      .trim()
      .max(1000, ERROR_MESSAGES.MAX_LENGTH(1000)),
  }),
  SEARCH_REPORTS: yup.object().shape({
    callerId: yup.string().trim().max(100, ERROR_MESSAGES.MAX_LENGTH(100)),
    startDate: yup.string().trim().required(ERROR_MESSAGES.REQUIRED),
    endDate: yup.string().trim().required(ERROR_MESSAGES.REQUIRED),
  }),
};
