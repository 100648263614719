import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { VALIDATION_SCHEMAS } from '../../../constants';
import BasicModal from '../../Common/BasicModel';
import { ErrorMessage } from '../../Shared';

export const CallNotesModal = ({
  open,
  formObjNotes,
  onClose,
  onChangeFormObjNotes,
  onChangeFormCallNotes,
}) => {
  const defaultValues = {
    callNotes: formObjNotes.callNotes,
  };

  const {
    control,
    formState: { isDirty },
    getValues,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.CALL_NOTES),
    mode: 'onChange',
    defaultValues,
  });

  const setTimeStamps = () => {
    const currentDate = new Date();
    const localeDateString = currentDate.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const localeTimeString = currentDate.toLocaleTimeString('en-US', {
      minute: '2-digit',
      hour: 'numeric',
      timeZoneName: 'short',
    });
    const timeStamps = `${localeDateString} ${localeTimeString}`;
    const prevCallNotes = getValues('callNotes');
    setValue(
      'callNotes',
      prevCallNotes ? prevCallNotes + '\n' + timeStamps : timeStamps,
      { shouldDirty: true, shouldValidate: true },
    );
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    const nextFormObjNotes = { ...formObjNotes, callNotes: data.callNotes };

    try {
      const response = await fetch('/call-options-notes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(nextFormObjNotes),
      });

      if (response.status === 400) {
        const invalidFields = await response.json();
        invalidFields?.validationErrors?.forEach(invalidField => {
          Object.entries(invalidField).forEach(([field, errors]) => {
            setError(field, { message: errors[0] });
          });
        });
        setIsSubmitting(false);
        return;
      }

      if (response.status !== 200) throw new Error();

      onChangeFormObjNotes(nextFormObjNotes);
      onChangeFormCallNotes(nextFormObjNotes.callNotes);
      setIsSubmitting(false);
      onClose();
    } catch {
      setIsSubmitting(false);
      toast.error("Can't save, please try again!", {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  });

  const isSaveButtonDisabled = isSubmitting || !isDirty;

  return (
    <BasicModal open={open} handleClose={isSubmitting ? undefined : onClose}>
      <div className="absolute left-[75%] bottom-[20%] -translate-x-[50%] cursor-move">
        <Draggable cancel={'textarea'}>
          <div className="m-3 flex justify-center mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl">
            <form onSubmit={onSubmit}>
              <div className="md:w-[576px] sm:w-[300px] pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
                <div className="flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3">
                  <h5 className="text-xl font-semibold">Call Notes</h5>
                  <button
                    onClick={onClose}
                    disabled={isSubmitting}
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    data-hs-overlay="#call-notes-modal"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-4 w-4 flex-shrink-0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>
                <div className="overflow-y-auto p-4">
                  <Controller
                    control={control}
                    name="callNotes"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      const hasErrorMessage = !!error?.message;
                      const borderColor = hasErrorMessage
                        ? 'border-red-500/20 focus:border-red-500'
                        : 'border-secondary/20 focus:border-blue-500';
                      const textareaClassName = `${borderColor} block resize-none w-full overflow-y-auto rounded-lg border-2 px-4 py-3 text-lg font-medium transition focus:outline-none focus:ring-2 focus:ring-blue-500/20 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2`;

                      return (
                        <>
                          <textarea
                            rows={5}
                            className={textareaClassName}
                            placeholder="Start typing here..."
                            autoFocus={true}
                            onChange={onChange}
                            value={value}
                          />
                          {hasErrorMessage && (
                            <ErrorMessage errorMessage={error.message} />
                          )}
                        </>
                      );
                    }}
                  />
                </div>
                <div className="flex items-center justify-between p-4">
                  <button
                    onClick={setTimeStamps}
                    type="button"
                    className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50"
                  >
                    Time Stamp
                  </button>
                  <button
                    disabled={isSaveButtonDisabled}
                    data-hs-overlay="#call-notes-modal"
                    type="submit"
                    className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Draggable>
      </div>
    </BasicModal>
  );
};
