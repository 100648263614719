import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import MangerDashboard from './components/ManagmentDasboard/MangerDashboard';
import './assets/css/aos.min.css';
import './assets/css/style.css';
import './assets/css/tailwind.css';
import './assets/css/tiny-slider.css'

import CallerDashboard from './components/CallerDashboard/CallerDashboard';
import ConversionLab from './components/ConversionLab';
import CallProcessing from './components/CallProcessing/CallProcessing';
import Login from './components/User/Login/Login';
import ForgotPassword from './components/User/ForgotPassword';
import ResetPassword from './components/User/ResetPassword';
import Register from './components/User/Register/Register';
import CallerStats from "./components/CallerStats/CallerStats";
import Report from "./components/Report/Report";
function App() {
  return (
    <BrowserRouter>
     <Routes>
      <Route path="/" element={<Navigate to={'login'}/>} />
      <Route path="/caller-dashboard" element={<CallerDashboard/>} />
      <Route path="/call-processing" element={<ConversionLab/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forgotpassword" element={<ForgotPassword/>} />
        <Route path="/resetpassword/:token" element={<ResetPassword/>} />
         <Route path="/mdashboard" element={<MangerDashboard/>} />
        <Route path="/caller-stats" element={<CallerStats/>} />
         <Route path="/reports" element={<Report/>} />

    </Routes>
    </BrowserRouter>
 
  );
}

export default App;
