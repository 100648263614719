import { useEffect, useRef, useState } from 'react';

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

let playPromise;

function AudioPlayer({ src }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(src));

  useEffect(() => {
    if (isPlaying) {
      playPromise = audioRef.current.play();
    } else {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            audioRef.current.pause();
          })
          .catch(error => {
            console.log('puase exception');
          });
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (src) {
      audioRef.current.src = src;
    } else {
      audioRef.current.src =
        'https://res.cloudinary.com/djvq8bgmo/video/upload/v1714146673/empty_lcaosp.mp3';
    }

    audioRef.current.addEventListener('loadeddata', () => {
      setDuration(audioRef.current.duration);
      setCurrentTime(0);
    });

    return () => {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            audioRef.current.pause();
            audioRef.current.removeEventListener('loadeddata', () => {
              setDuration(audioRef.current.duration);
              setCurrentTime(0);
            });
          })
          .catch(error => {
            console.log('puase exception');
          });
      }
    };
  }, [src]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(audioRef.current.currentTime);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.muted = !audioRef.current.muted;
  };

  const onTimeChange = e => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    audioRef.current.currentTime = time;
  };

  const backgroundSize = `${(currentTime / duration) * 100}%`;

  return (
    <div className="flex items-center space-x-4">
      <button onClick={togglePlayPause}>
        {isPlaying ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 5.25v13.5m-7.5-13.5v13.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
            />
          </svg>
        )}
      </button>
      <input
        type="range"
        min="0"
        max={duration || 1}
        value={currentTime}
        onChange={onTimeChange}
        className="flex-1 h-1 bg-gray appearance-none"
        style={{
          background: `linear-gradient(to right, #0d0d73 ${backgroundSize}, transparent ${backgroundSize}, transparent 100%), 100% center #ddd`,
        }}
      />
      <span>{formatTime(currentTime)}</span> /{' '}
      <span>{formatTime(duration)}</span>
      <button onClick={toggleMute}>
        {isMuted ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
            />
          </svg>
        )}
      </button>
    </div>
  );
}

export default AudioPlayer;
