// import moment from 'moment';
import React, {useState} from 'react'
import moment from 'moment-timezone';

const Schedule3 = ({setIndex, toggleIsPlayer,timeInfo,pickerInfo,happenedIndex,appointment, updateDialoutcomes, handleFinish, handleOpen3}) => {
    const getUserTimeZone = () => {
        const userTimeZone = moment.tz.guess(); // Get user's time zone
        const userTimeZoneAbbreviation = moment().tz(userTimeZone).format('z');
        return userTimeZoneAbbreviation;
        // Get abbreviation
        // setUserTimeZoneAbbreviation(userTimeZoneAbbreviation);
    };
    const [finishClicked, setFinishClicked] = useState(false);

    return (
        <><div
            class="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-10 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
            <div class="space-y-4">
                <p class="text-base font-medium lg:text-lg xl:text-xl">
                    Does the information below look correct?
                </p>

                <form action="" class="">
                    <div class="space-y-4">
                        <div class="relative">
                            <input
                                readOnly
                                type="text"
                                id="date"
                                class="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                                placeholder="Date"
                                value={moment(pickerInfo).format('MMMM Do YYYY')} />
                            <label
                                for="date"
                                class="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500">
                                Date
                            </label>
                        </div>

                        <div class="relative">
                            <input
                                readOnly
                                type="text"
                                id="time"
                                class="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                                placeholder="Time"
                                value={`${timeInfo} ${getUserTimeZone()}`} />
                            <label
                                for="time"
                                class="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500">
                                Time
                            </label>
                        </div>
                        {happenedIndex === 0 && 
                        
                        <div class="relative">
                            <input
                                readOnly
                                type="text"
                                id="appointmentType"
                                class="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                                placeholder="Appointment Type"
                                value={appointment} />
                            <label
                                for="appointmentType"
                                class="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500">
                                Appointment Type
                            </label>
                        </div>
                        }
                    </div>
                </form>
            </div>
        </div>

            <div class="cursor-pointer flex flex-wrap items-center justify-between gap-2">
                <a
                    onClick={()=>{setIndex(3)}}
                    class="inline-flex items-center gap-x-2 text-sm text-gray-800 transition hover:text-secondary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        class="h-4 w-4"
                        viewBox="0 0 24 24">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Go Back
                </a>
                {happenedIndex === 1 && (
                    <button
                        onClick={() => { setFinishClicked(true); updateDialoutcomes(happenedIndex); handleFinish(); }}
                        class={`inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg`}
                        disabled={finishClicked}>
                        Finish
                    </button>
                )}
                {happenedIndex !== 1 && (
                    <a
                        onClick={()=>{
                            setIndex(5); toggleIsPlayer("visible")
                        }}
                        class="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-10 py-2 text-lg font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50">
                        Yes, looks good!

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2.5"
                            class="h-4 w-4"
                            viewBox="0 0 24 24">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </a>
                )}
                <span
                    onClick={handleOpen3}
                    data-hs-overlay="#call-notes-modal"
                    className="cursor-pointer rounded-lg bg-transparent-1 p-1 text-primary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="h-6 w-6"
                    >
                        <path
                            d="M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z" />
                    </svg>
                </span>
            </div>
        </>
    )
}

export default Schedule3
