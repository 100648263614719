import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { VALIDATION_SCHEMAS } from '../../../constants';
import BasicModal from '../../Common/BasicModel';
import { ErrorMessage, TextInputController } from '../../Shared';

const EMPTY_STRING = '';

export const EditContactModal = ({
  open,
  onClose,
  contact,
  onChangeContact,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [isTimeZonesLoading, setIsTimeZonesLoading] = useState(false);
  const [isTimeZonesError, setIsTimeZonesError] = useState(false);

  const defaultValues = {
    company: contact.company,
    contact: contact.contact ?? EMPTY_STRING,
    title: contact.title ?? EMPTY_STRING,
    industry: contact.industry ?? EMPTY_STRING,
    time: contact.time,
    address: contact.address ?? EMPTY_STRING,
    city: contact.city ?? EMPTY_STRING,
    state: contact.state ?? EMPTY_STRING,
    website: contact.website ?? EMPTY_STRING,
    email: contact.email ?? EMPTY_STRING,
    linkedin: contact.linkedin ?? EMPTY_STRING,
  };

  const {
    control,
    formState: { isDirty },
    watch,
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.EDIT_CONTACT),
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    const nextContact = { ...contact, ...data };

    try {
      const response = await fetch('/call-contact-update', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(nextContact),
      });

      if (response.status === 400) {
        const invalidFields = await response.json();
        invalidFields?.validationErrors?.forEach(invalidField => {
          Object.entries(invalidField).forEach(([field, errors]) => {
            setError(field, { message: errors[0] });
          });
        });
        setIsSubmitting(false);
        return;
      }

      if (response.status !== 200) throw new Error();

      onChangeContact(nextContact);
      setIsSubmitting(false);
      onClose();
    } catch {
      setIsSubmitting(false);
      toast.error("Can't save, please try again!", {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  });

  const fetchTimeZones = useCallback(async () => {
    setIsTimeZonesLoading(true);
    setIsTimeZonesError(false);

    try {
      const response = await fetch('/timezones');

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      setTimeZones(data.timeZones);
      setIsTimeZonesLoading(false);
    } catch {
      setIsTimeZonesLoading(false);
      setIsTimeZonesError(true);
      toast.error("Can't get other available Time Zones", {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, []);

  useEffect(() => {
    fetchTimeZones();
  }, [fetchTimeZones]);

  const isSaveChangesButtonDisabled = isSubmitting || !isDirty;
  const isTimeZoneSelectDisabled = isTimeZonesLoading || isTimeZonesError;
  const watchTime = watch('time');
  const nextTimeZones = timeZones.filter(timeZone => timeZone !== watchTime);

  return (
    <BasicModal open={open} handleClose={isSubmitting ? undefined : onClose}>
      <div
        id="edit-contact-modal"
        className="hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden"
      >
        <div className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <div className="flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3">
              <h5 className="text-xl font-semibold">Contact Information</h5>
              <button
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-overlay="#edit-contact-modal"
                onClick={onClose}
                disabled={isSubmitting}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-4 w-4 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            <form onSubmit={onSubmit}>
              <div className="overflow-y-auto p-5">
                <div className="space-y-4">
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                    <TextInputController
                      control={control}
                      name="company"
                      label="Company Name"
                      placeholder="Company Name"
                    />
                    <TextInputController
                      control={control}
                      name="contact"
                      label="Contact Name"
                      placeholder="Contact Name"
                    />
                  </div>
                  <TextInputController
                    control={control}
                    name="title"
                    label="Title"
                    placeholder="Title"
                  />
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                    <TextInputController
                      control={control}
                      name="industry"
                      label="Industry"
                      placeholder="Industry"
                    />
                    <Controller
                      control={control}
                      name="time"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        const hasErrorMessage = !!error?.message;
                        const containerClassName = 'relative h-[62px]';
                        const borderBottomColor = hasErrorMessage
                          ? 'border-b-red-500/20 focus:border-b-red-500'
                          : 'border-b-secondary/20 focus:border-b-secondary';
                        const selectClassName = `${borderBottomColor} text-align-last-left absolute bottom-0 flex peer h-[46px] w-full border-b-2 border-x-transparent border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent focus:border-x-transparent focus:border-t-transparent focus:pb-2 focus:pt-2 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-2`;
                        const labelClassName =
                          'pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500';

                        return (
                          <div>
                            <div className={containerClassName}>
                              <select
                                value={value}
                                onChange={onChange}
                                className={selectClassName}
                                disabled={isTimeZoneSelectDisabled}
                              >
                                <option value={value}>{value}</option>
                                {nextTimeZones.map(timeZone => (
                                  <option key={timeZone} value={timeZone}>
                                    {timeZone}
                                  </option>
                                ))}
                              </select>
                              <label className={labelClassName}>
                                Time Zone
                              </label>
                            </div>
                            {hasErrorMessage && (
                              <ErrorMessage errorMessage={error.message} />
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                    <TextInputController
                      control={control}
                      name="address"
                      label="Contact Address"
                      placeholder="Contact Address"
                    />
                    <TextInputController
                      control={control}
                      name="city"
                      label="City"
                      placeholder="City"
                    />
                    <TextInputController
                      control={control}
                      name="state"
                      label="State"
                      placeholder="State"
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-1">
                    <TextInputController
                      control={control}
                      name="website"
                      label="Website"
                      placeholder="Website"
                    />
                    <TextInputController
                      control={control}
                      name="email"
                      label="Email"
                      placeholder="Email"
                    />
                    <TextInputController
                      control={control}
                      name="linkedin"
                      label="LinkedIn"
                      placeholder="LinkedIn"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between p-4">
                <button
                  data-hs-overlay="#edit-contact-modal"
                  type="button"
                  className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50"
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  data-hs-overlay="#edit-contact-modal"
                  type="submit"
                  className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg"
                  disabled={isSaveChangesButtonDisabled}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
