import { useState } from 'react';

import { ButtonCallNotes, ButtonFinish, ButtonGoBack } from '../Shared';

const WhatHappened = ({
  setIndex,
  setHappenedIndex,
  happenedIndex,
  updateDialoutcomes,
  handleOpen3,
  formCallNotes,
}) => {
  const [finishClicked, setFinishClicked] = useState(false);
  const [happenedContent, setHappenedContent] = useState('');
  const [isPositioned, setIsPositioned] = useState(false);
  setTimeout(() => setIsPositioned(true), 1000);

  return (
    <>
      <div className="flex h-full flex-col justify-between gap-4">
        <div className="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 2xl:p-16 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
          <h4 className="text-center text-lg font-semibold xl:text-xl">
            What happened on your call?
          </h4>

          <div className="mt-8 flex flex-wrap items-center justify-center gap-4">
            <a
              onClick={() => {
                setIndex(2);
                setHappenedIndex(0);
              }}
              style={{
                transition: `transform ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-out, opacity ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            >
              Booked an appointment
            </a>
            <a
              onClick={() => {
                setIndex(2);
                setHappenedIndex(1);
              }}
              style={{
                transition: `transform ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-out, opacity ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            >
              Scheduled a callback
            </a>
            <a
              onClick={() => {
                setIndex(7);
                setHappenedIndex(2);
              }}
              style={{
                transition: `transform ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-out, opacity ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            >
              Send an email
            </a>
          </div>

          <div className="mt-12 flex flex-wrap items-center justify-center gap-4">
            <a
              onClick={() => {
                setHappenedContent('Hung up');
                setHappenedIndex(4);
              }}
              style={{
                transition: `transform ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-out, opacity ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} ${happenedContent === 'Hung up' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''}`}
            >
              Hung up
            </a>
            <a
              onClick={() => {
                setIndex(16);
                setHappenedIndex(5);
              }}
              style={{
                transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            >
              Not interested
            </a>
          </div>

          <div className="mt-4 flex flex-wrap items-center justify-center gap-4">
            <a
              onClick={() => {
                setHappenedContent('No answer');
                setHappenedIndex(6);
              }}
              style={{
                transition: `transform ${1.5 - 5 * 0.1}s ${5 * 0.2}s ease-out, opacity ${1.5 - 5 * 0.1}s ${5 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} ${happenedContent === 'No answer' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''}`}
            >
              No answer
            </a>
            <a
              onClick={() => {
                setIndex(18);
                setHappenedIndex(8);
              }}
              style={{
                transition: `transform ${1.5 - 6 * 0.1}s ${6 * 0.2}s ease-out, opacity ${1.5 - 6 * 0.1}s ${6 * 0.2}s ease-in-out`,
              }}
              className={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            >
              Do Not Call
            </a>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <ButtonGoBack
            onClick={() => {
              setIndex(0);
            }}
          />
          {(happenedIndex === 4 || happenedIndex === 6) && (
            <ButtonFinish
              onClick={() => {
                updateDialoutcomes(happenedIndex);
                setFinishClicked(true);
              }}
              disabled={finishClicked}
            />
          )}
          <ButtonCallNotes
            onClick={handleOpen3}
            hasBg={formCallNotes && formCallNotes !== 'null'}
          />
        </div>
      </div>
    </>
  );
};

export default WhatHappened;
