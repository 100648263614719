import { useState } from 'react';

import { RecordingsSearchContext } from '../context';

export const RecordingsSearchProvider = ({ children }) => {
  const [requestBody, setRequestBody] = useState(null);
  const [isSearchByContactNameValid, setIsSearchByContactNameValid] =
    useState(true);

  const onChangeRequestBody = nextRequestBody => {
    setRequestBody(nextRequestBody);
  };

  return (
    <RecordingsSearchContext.Provider
      value={{
        requestBody,
        isSearchByContactNameValid,
        onChangeRequestBody,
        setIsSearchByContactNameValid,
      }}
    >
      {children}
    </RecordingsSearchContext.Provider>
  );
};
