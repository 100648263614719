export default function CallIcon(props) {
  return (
    <span className='rounded-lg bg-accent-1 p-1 text-primary'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={props.className}
        viewBox='0 0 400 393'
      >
        <path
          d='M95 6c-17 5-44 24-57 39-16 18-28 60-26 86 3 35 12 61 37 112 15 30 37 59 55 73 41 33 99 62 145 72 48 10 106-11 130-47 25-36 22-62-8-80l-67-30c-23-9-37-6-60 10-20 14-23 15-35 15h-8l-7-3c-16-8-39-31-46-46-6-15-4-26 10-45 25-35 25-44-8-115-17-36-32-47-55-41m12 35c15 6 38 52 39 75 0 15 0 16-25 48-15 20-12 38 12 70 12 15 23 25 45 41 32 22 39 22 69-2 27-22 34-22 78-2 36 17 43 26 37 45-11 36-65 54-119 40-63-17-98-38-143-89-24-28-43-65-51-102-5-20-5-65 0-81 8-29 39-52 58-43m156 8-13 2-4 1v27l5 1c13 3 106 1 114-2h2V53l-4-1c-11-3-78-5-100-3m-2 77-15 3-4 2v4l1 12v8l4 1c11 3 18 3 79 2l37-2 5-2-1-12v-12l-3-1c-9-3-16-3-59-3h-44'
          fillRule='evenodd'
        />
      </svg>
    </span>
  );
}
