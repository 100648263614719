import { Button, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DATE_TIME_FORMAT } from '../../constants';
import { PointerStatsModal } from '../Modals';

const APPOINTMENTS_PROMPT = `
I am a cold caller and I have not quite reached my goal of 2 booked appointments per day. Offer 1 recommendation on how to book more appointments via cold calling.`;
const DIALS_PROMPT = `
I am a cold caller and I am falling behind on my dials. Write one recommendation on how to increase the number of my dials via cold calling. The response should be separated in paragraph.`;
const DAILY_APPOINTMENTS_STANDARD = 2;
const DAILY_DIALS_STANDARD = 100;
const INITIAL_CALL_STAT = {
  firstAppt: 0,
  totalDials: 0,
  callback: 0,
  connectionPerc: 0,
  conversionRate: 0,
  doNotCall: 0,
  email: 0,
  hangUp: 0,
  noAns: 0,
  notInterested: 0,
  referral: 0,
  totalReaches: 0,
};
const STATE = {
  HAS_DATA: 'hasData',
  IS_LOADING: 'isLoading',
  IS_ERROR: 'isError',
};

const getState = (isLoading, isError) => {
  if (isLoading) return STATE.IS_LOADING;
  if (isError) return STATE.IS_ERROR;
  return STATE.HAS_DATA;
};

const PointerStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [myCallStat, setMyCallStat] = useState(INITIAL_CALL_STAT);
  const [teamCallStat, setTeamCallStat] = useState(INITIAL_CALL_STAT);

  const [isAppointmentsModalOpened, setIsAppointmentsModalOpened] =
    useState(false);
  const [isDialsModalOpened, setIsDialsModalOpened] = useState(false);

  const openAppointmentsModal = () => setIsAppointmentsModalOpened(true);
  const closeAppointmentsModal = () => setIsAppointmentsModalOpened(false);

  const openDialsModal = () => setIsDialsModalOpened(true);
  const closeDialsModal = () => setIsDialsModalOpened(false);

  const setCallStats = callStats => {
    if (callStats.length === 0) return;

    if (callStats[0].CallerOrTeam === 'Team Average') {
      setTeamCallStat({
        firstAppt: callStats[0].FirstAppt,
        totalDials: callStats[0].TotalDials,
        callback: callStats[0].Callback,
        connectionPerc: callStats[0].ConnectionPerc,
        conversionRate: callStats[0].ConversionRate,
        doNotCall: callStats[0].DoNotCall,
        email: callStats[0].Email,
        hangUp: callStats[0].HangUp,
        noAns: callStats[0].NoAns,
        notInterested: callStats[0].NotInterested,
        referral: callStats[0].Referral,
        totalReaches: callStats[0].TotalReaches,
      });
      if (callStats.length > 1) {
        setMyCallStat({
          firstAppt: callStats[1].FirstAppt,
          totalDials: callStats[1].TotalDials,
          callback: callStats[1].Callback,
          connectionPerc: callStats[1].ConnectionPerc,
          conversionRate: callStats[1].ConversionRate,
          doNotCall: callStats[1].DoNotCall,
          email: callStats[1].Email,
          hangUp: callStats[1].HangUp,
          noAns: callStats[1].NoAns,
          notInterested: callStats[1].NotInterested,
          referral: callStats[1].Referral,
          totalReaches: callStats[1].TotalReaches,
        });
      }
    } else {
      setMyCallStat({
        firstAppt: callStats[0].FirstAppt,
        totalDials: callStats[0].TotalDials,
        callback: callStats[0].Callback,
        connectionPerc: callStats[0].ConnectionPerc,
        conversionRate: callStats[0].ConversionRate,
        doNotCall: callStats[0].DoNotCall,
        email: callStats[0].Email,
        hangUp: callStats[0].HangUp,
        noAns: callStats[0].NoAns,
        notInterested: callStats[0].NotInterested,
        referral: callStats[0].Referral,
        totalReaches: callStats[0].TotalReaches,
      });
      if (callStats.length > 1) {
        setTeamCallStat({
          firstAppt: callStats[1].FirstAppt,
          totalDials: callStats[1].TotalDials,
          callback: callStats[1].Callback,
          connectionPerc: callStats[1].ConnectionPerc,
          conversionRate: callStats[1].ConversionRate,
          doNotCall: callStats[1].DoNotCall,
          email: callStats[1].Email,
          hangUp: callStats[1].HangUp,
          noAns: callStats[1].NoAns,
          notInterested: callStats[1].NotInterested,
          referral: callStats[1].Referral,
          totalReaches: callStats[1].TotalReaches,
        });
      }
    }
  };

  const fetchCallStats = useCallback(async () => {
    const currentDate = moment().format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH);
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await fetch('/call-stats-team', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          startDate: currentDate,
          endDate: currentDate,
          reportType: 'Daily',
        }),
      });

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      setCallStats(data.callStats);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setIsError(true);
      toast.error('Failed to load call stats!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, []);

  useEffect(() => {
    fetchCallStats();
  }, [fetchCallStats]);

  const state = getState(isLoading, isError);

  return (
    <>
      <div className="container mx-auto flex flex-1 flex-col">
        {state === STATE.IS_LOADING && (
          <div className="flex justify-center items-center flex-1">
            <CircularProgress size={80} sx={{ color: '#1F68A2' }} />
          </div>
        )}
        {state === STATE.IS_ERROR && (
          <div className="flex justify-center items-center flex-1 flex-col gap-6">
            <div className="flex justify-center items-center flex-col gap-3">
              <span className="text-2xl font-medium">
                Failed to load call stats
              </span>
              <span className="text-lg font-medium text-[#6b7280]">
                Error occurred while loading. Try again.
              </span>
            </div>
            <Button
              disableFocusRipple
              disableRipple
              variant="text"
              sx={{
                color: '#FF344C',
                fontFamily: 'inherit',
                textTransform: 'none',
                fontSize: 21,
                padding: 0,
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'transparent',
                  color: '#FF5A6D',
                },
              }}
              onClick={fetchCallStats}
            >
              Reload
            </Button>
          </div>
        )}
        {state === STATE.HAS_DATA && (
          <>
            <h6 className="text-primary text-center font-bold py-2">
              This is how you currently compare to your team.
            </h6>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="py-2 px-4"></th>
                  <th className="bg-secondary/50 text-primary font-semibold py-2 px-4 rounded-tl-lg">
                    You
                  </th>
                  <th className="bg-secondary/10 text-primary font-semibold py-2 px-4 rounded-tr-lg">
                    Team
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Appointments
                  </td>
                  {myCallStat.firstAppt < DAILY_APPOINTMENTS_STANDARD ? (
                    <td
                      className="px-4 py-1 h-py-2 bg-secondary/50 text-red-600 font-bold text-center cursor-pointer"
                      onClick={openAppointmentsModal}
                      data-hs-overlay="#appt-response-modal"
                    >
                      {myCallStat.firstAppt}
                    </td>
                  ) : (
                    <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                      {myCallStat.firstAppt}
                    </td>
                  )}
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.firstAppt}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Dials
                  </td>
                  {myCallStat.totalDials < DAILY_DIALS_STANDARD ? (
                    <td
                      className="px-4 py-1 h-py-2 bg-secondary/50 text-red-600 font-bold text-center cursor-pointer"
                      onClick={openDialsModal}
                      data-hs-overlay="#dial-response-modal"
                    >
                      {myCallStat.totalDials}
                    </td>
                  ) : (
                    <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                      {myCallStat.totalDials}
                    </td>
                  )}
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.totalDials}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Connection Rate
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {Math.round(myCallStat.connectionPerc * 100)}%
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {Math.round(teamCallStat.connectionPerc * 100)}%
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Conversion Rate
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {Math.round(myCallStat.conversionRate * 100)}%
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {Math.round(teamCallStat.conversionRate * 100)}%
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Callback
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {myCallStat.callback}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.callback}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Email
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {myCallStat.email}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.email}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    No Answer
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {myCallStat.noAns}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.noAns}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Hang up
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {myCallStat.hangUp}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.hangUp}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Not Interested
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {myCallStat.notInterested}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    {teamCallStat.notInterested}
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Do Not Call
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-bl-lg">
                    {myCallStat.doNotCall}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-br-lg">
                    {teamCallStat.doNotCall}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
      {isAppointmentsModalOpened && (
        <PointerStatsModal
          open={isAppointmentsModalOpened}
          onClose={closeAppointmentsModal}
          prompt={APPOINTMENTS_PROMPT}
        />
      )}
      {isDialsModalOpened && (
        <PointerStatsModal
          open={isDialsModalOpened}
          onClose={closeDialsModal}
          prompt={DIALS_PROMPT}
        />
      )}
    </>
  );
};

export default PointerStats;
