export default function PlayIcon(props) {
  return (
    <svg
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <circle
        cx='21'
        cy='21'
        r='20'
        fill='#7E7EE4'
        stroke='#4040DC'
        strokeWidth='2'
      />
      <path
        d='M21 7.63636C18.3569 7.63636 15.7732 8.42013 13.5756 9.88854C11.3779 11.357 9.66508 13.4441 8.65362 15.886C7.64215 18.3278 7.37751 21.0148 7.89315 23.6071C8.40879 26.1994 9.68155 28.5806 11.5505 30.4495C13.4194 32.3185 15.8006 33.5912 18.3929 34.1069C20.9852 34.6225 23.6722 34.3578 26.114 33.3464C28.5559 32.3349 30.643 30.6221 32.1115 28.4244C33.5799 26.2268 34.3636 23.6431 34.3636 21C34.3599 17.4569 32.9507 14.06 30.4454 11.5546C27.94 9.04926 24.5431 7.64011 21 7.63636ZM21 32.3077C18.7636 32.3077 16.5773 31.6445 14.7178 30.402C12.8582 29.1595 11.4089 27.3935 10.5531 25.3273C9.69721 23.2611 9.47328 20.9875 9.90959 18.794C10.3459 16.6005 11.4229 14.5857 13.0043 13.0043C14.5857 11.4228 16.6005 10.3459 18.794 9.90958C20.9875 9.47327 23.2611 9.6972 25.3273 10.5531C27.3935 11.4089 29.1595 12.8582 30.402 14.7178C31.6445 16.5773 32.3077 18.7635 32.3077 21C32.3043 23.9979 31.1119 26.8721 28.992 28.992C26.8721 31.1119 23.9979 32.3043 21 32.3077ZM27.1987 20.1288L18.9749 14.9889C18.8193 14.8917 18.6406 14.8379 18.4572 14.833C18.2738 14.8282 18.0924 14.8725 17.932 14.9614C17.7715 15.0503 17.6377 15.1805 17.5446 15.3386C17.4514 15.4966 17.4022 15.6767 17.4021 15.8601V26.1399C17.4022 26.3233 17.4514 26.5034 17.5446 26.6614C17.6377 26.8195 17.7715 26.9497 17.932 27.0386C18.0924 27.1275 18.2738 27.1718 18.4572 27.167C18.6406 27.1621 18.8193 27.1083 18.9749 27.0111L27.1987 21.8712C27.3463 21.7787 27.468 21.6503 27.5524 21.4979C27.6367 21.3455 27.681 21.1742 27.681 21C27.681 20.8258 27.6367 20.6545 27.5524 20.5021C27.468 20.3497 27.3463 20.2212 27.1987 20.1288ZM19.458 24.2857V17.7143L24.7148 21L19.458 24.2857Z'
        fill='#4040DC'
      />
    </svg>
  );
}
