import { useContext } from 'react';

import { RefreshTokenContext } from '../context';

export const useRefreshTokenContext = () => {
  const refreshTokenContext = useContext(RefreshTokenContext);

  if (!refreshTokenContext)
    throw new Error('[RefreshTokenContext]: unexpected error');

  return refreshTokenContext;
};
