import { useState } from 'react';

import { ButtonCallNotes, ButtonFinish, ButtonGoBack } from '../Shared';

const NotInterested = ({
  setIndex,
  happenedIndex,
  updateDialoutcomes,
  handleOpen3,
  formCallNotes,
}) => {
  const [notInterestedReason, setNotInterestedReason] = useState('');
  const [finishClicked, setFinishClicked] = useState(false);
  const [isPositioned, setIsPositioned] = useState(false);
  setTimeout(() => setIsPositioned(true), 1000);

  return (
    <>
      {' '}
      <div className="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-10 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <h4 className="text-center text-lg font-semibold xl:text-xl">
          Why is the prospect not interested?
        </h4>

        <div className="cursor-pointer mt-6 flex flex-col items-center justify-center gap-4">
          <a
            onClick={() => setNotInterestedReason('No budget')}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === 'No budget' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
            }}
          >
            No budget
          </a>
          <a
            onClick={() => setNotInterestedReason('Uses competitor')}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === 'Uses competitor' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
            }}
          >
            Uses competitor
          </a>
          <a
            onClick={() => setNotInterestedReason('Not a good time')}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === 'Not a good time' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
            }}
          >
            Not a good time
          </a>
          <a
            onClick={() => setNotInterestedReason('No need for our service')}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg ${notInterestedReason === 'No need for our service' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
            }}
          >
            No need for our service
          </a>
        </div>
      </div>
      <div className="cursor-pointer flex flex-wrap items-center justify-between gap-2">
        <ButtonGoBack
          onClick={() => {
            setIndex(1);
          }}
        />
        <ButtonFinish
          onClick={() => {
            updateDialoutcomes(happenedIndex);
            setFinishClicked(true);
          }}
          disabled={finishClicked}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </>
  );
};

export default NotInterested;
