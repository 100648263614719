import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { VALIDATION_SCHEMAS } from '../../../constants';
import BasicModal from '../../Common/BasicModel';
import { ErrorMessage } from '../../Shared';

export const ScriptModal = ({
  open,
  onClose,
  scriptSelected,
  scriptEdit,
  setScriptSelected,
  setTriggerCallerScipts,
  openOptimizeScriptWithAIModal,
  setScriptEdit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues = {
    ScriptName: scriptEdit.ScriptName,
    ScriptDescription: scriptEdit.ScriptDescription,
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.SCRIPT),
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = handleSubmit(data => {
    setIsSubmitting(true);
    const scriptDescriptionUpdate = data.ScriptDescription.replaceAll(
      "'",
      "''",
    );
    fetch('/api/script/update-script', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ScriptName: data.ScriptName,
        ScriptDescription: scriptDescriptionUpdate,
        CallerScriptID: scriptEdit.CallerScriptID,
      }),
    })
      .then(response => {
        if (response.status !== 200) throw new Error();

        setIsSubmitting(false);
        setScriptEdit(prev => ({
          ...prev,
          ...data,
        }));
        onClose();
        if (scriptSelected) {
          setTimeout(() => openOptimizeScriptWithAIModal(), 2800);
          setScriptSelected(false);
          setTimeout(() => setScriptSelected(true), 10);
        }

        setTimeout(() => setTriggerCallerScipts(prev => prev + 1), 500);
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.error('Something went wrong, please try again!', {
          position: 'top-right',
          autoClose: 3000,
        });
      });
  });

  const handleSubmitScriptDelete = () => {
    if (!scriptEdit.CallerScriptID) {
      toast.info("Can't delete script without ID", {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    setIsSubmitting(true);
    fetch('/api/script/delete-script', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(scriptEdit),
    })
      .then(response => {
        if (response.status !== 200) throw new Error();
        onClose();
        setIsSubmitting(false);
        setScriptSelected(false);
        setTriggerCallerScipts(prev => prev + 1);
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.error("Can't delete script, please try again!", {
          position: 'top-right',
          autoClose: 3000,
        });
      });
  };

  const isSaveButtonDisabled = !isDirty || isSubmitting;

  return (
    <BasicModal open={open} handleClose={isSubmitting ? undefined : onClose}>
      <div
        id="edit-script-modal"
        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
      >
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <div className="flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3">
              <h5 className="text-xl font-semibold">
                {scriptSelected ? 'Edit Script' : 'Add Script'}
              </h5>
              <button
                onClick={onClose}
                disabled={isSubmitting}
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-overlay="#call-notes-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-4 w-4 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            <form onSubmit={onSubmit}>
              <Controller
                control={control}
                name="ScriptName"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  const hasErrorMessage = !!error?.message;
                  const borderBottomColor = hasErrorMessage
                    ? 'border-b-red-500/20 focus:border-b-red-500'
                    : 'border-b-secondary/20 focus:border-b-secondary';
                  const inputClassName = `${borderBottomColor} peer py-3 block w-full border-b-2 border-x-transparent border-t-transparent bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6`;

                  return (
                    <div className="relative px-5 pb-5">
                      <input
                        id="scriptNameInput"
                        className={inputClassName}
                        placeholder="Script Name"
                        value={value}
                        readOnly={scriptSelected}
                        autoFocus={!scriptSelected}
                        onChange={onChange}
                      />
                      <label
                        htmlFor="scriptNameInput"
                        className="px-5 py-3 pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-5 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
                      >
                        Script Name
                      </label>
                      {hasErrorMessage && (
                        <ErrorMessage errorMessage={error.message} />
                      )}
                    </div>
                  );
                }}
              />

              <Controller
                control={control}
                name="ScriptDescription"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  const hasErrorMessage = !!error?.message;
                  const borderColor = hasErrorMessage
                    ? 'border-red-500/20 focus:border-red-500'
                    : 'border-secondary/20 focus:border-blue-500';
                  const textareaClassName = `${borderColor} block w-full rounded-lg border-2 px-4 py-3 text-lg font-medium transition focus:outline-none focus:ring-2 focus:ring-blue-500/20`;

                  return (
                    <div className="overflow-y-auto text-lg px-5 pb-5">
                      <textarea
                        value={value}
                        onChange={onChange}
                        rows={5}
                        autoFocus={scriptSelected}
                        className={textareaClassName}
                      ></textarea>
                      {hasErrorMessage && (
                        <ErrorMessage errorMessage={error.message} />
                      )}
                    </div>
                  );
                }}
              />

              <div className="flex items-center justify-between p-4">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`${
                      scriptEdit.CallerScriptID ? 'inline-flex' : 'hidden'
                    } items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 ml-2`}
                    onClick={handleSubmitScriptDelete}
                    disabled={isSubmitting}
                  >
                    Delete
                  </button>
                </div>

                <button
                  disabled={isSaveButtonDisabled}
                  type="submit"
                  className="inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
