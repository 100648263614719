import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Logo from '../../assets/header/login/img/logo.png';
import LogoTo from '../../assets/header/login/img/logo-2.png';
import { ErrorMessage } from '../../components/Shared';
import { VALIDATION_SCHEMAS } from '../../constants';

export const ForgotPassword = () => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.FORGOT_PASSWORD),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const onSubmit = handleSubmit(data => {
    setIsSubmitting(true);
    fetch('/api/auth/forgot-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.email }),
    })
      .then(response => {
        setIsSubmitting(false);
        if (response.status === 200) {
          toast.info('Password reset email sent successfully.', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          setEmailSent(true);
        } else if (response.status === 404) {
          toast.info('Email is not registered in our system.', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else {
          toast.info('Something went wrong.', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.info('Something went wrong.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  });
  useEffect(() => {
    document.title = 'Accelerate - Forgot Your Password?';
  }, []);

  return (
    <div>
      <nav className="header" id="header">
        <div className="c-grid-fluid">
          <div className="header__wrapper">
            <div>
              <div className="logo logo--header">
                <a className="logo__link" href="/">
                  <img className="logo__image" src={Logo} alt="Logotype" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <main className="main main--start p-sign-in">
        <section className="sign" id="sign">
          {!emailSent && (
            <div className="c-grid">
              <div className="section__wrapper">
                <div className="c-section" data-align="center">
                  <div className="c-section__head">
                    <div className="logo logo--header">
                      <a className="logo__link" href="/">
                        <img
                          className="logo__image"
                          src={LogoTo}
                          alt="Logotype"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="c-section__body">
                    <h5 className="c-section__title">Reset Your Password</h5>

                    <form
                      className="c-form"
                      action=""
                      method=""
                      autoComplete="off"
                      onSubmit={onSubmit}
                    >
                      <div className="c-form__field">
                        <label className="c-form__label" htmlFor="email">
                          Email
                        </label>
                        <Controller
                          control={control}
                          name="email"
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            const hasErrorMessage = !!error?.message;

                            return (
                              <>
                                <input
                                  className="c-form__input"
                                  placeholder="Enter your email address"
                                  value={value}
                                  onChange={onChange}
                                />
                                {hasErrorMessage && (
                                  <ErrorMessage errorMessage={error.message} />
                                )}
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="c-form__field c-form__field--btn">
                        <div className="c-btn__wrapper">
                          <input
                            className="c-btn"
                            type={'submit'}
                            data-btn-color="blue"
                            data-btn-size="full"
                            data-btn-fw="600"
                            data-btn-round="half"
                            value={'Send'}
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {emailSent && (
            <div className="c-grid">
              <div className="section__wrapper">
                <div className="c-section" data-align="center">
                  <div className="c-section__head">
                    <div className="logo logo--header">
                      <a className="logo__link" href="/">
                        <img
                          className="logo__image"
                          src={LogoTo}
                          alt="Logotype"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="c-section__body">
                    <h5 className="c-section__title">
                      An email has been sent to reset your password.
                    </h5>
                    <p className="p-2">
                      Can't find the email? Try checking your spam folder or
                      click{' '}
                      <span
                        onClick={onSubmit}
                        className="text-secondary cursor-pointer"
                      >
                        resend
                      </span>{' '}
                      to try again.
                    </p>
                    <p className="p-2">
                      Still having issues? Contact{' '}
                      <a
                        href="mailto:support@prospectiq.ai"
                        className="text-secondary"
                      >
                        support@prospectiq.ai
                      </a>
                      .
                    </p>
                    <p className="p-2">
                      Remember password? Try to{' '}
                      <a href="/login" className="text-secondary">
                        login
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </main>
      <footer className="footer" id="footer">
        <div className="c-grid">
          <div className="footer__wrapper"></div>
        </div>
      </footer>
    </div>
  );
};
