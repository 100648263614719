// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LicenseModal_modalTitle__t\\+0D8 {
  font-size: 1.1rem;
  font-weight: 600;
}

.LicenseModal_licenseModal__uXs\\+h {
  width: 90%;
  max-width: 700px;
  padding: 20px;
  border: none;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.LicenseModal_modalBody__85adJ {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
}

.LicenseModal_modalFooter__PETnj {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

body.LicenseModal_modalOpen__fr9S3 {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/LicenseModal/LicenseModal.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,2CAA2C;EAC3C,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modalTitle {\n  font-size: 1.1rem;\n  font-weight: 600;\n}\n\n.licenseModal {\n  width: 90%;\n  max-width: 700px;\n  padding: 20px;\n  border: none;\n  background: white;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n  border-radius: 8px;\n  text-align: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.modalBody {\n  max-height: 300px;\n  overflow-y: auto;\n  padding: 10px;\n  margin-bottom: 10px;\n}\n\n.modalFooter {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n}\n\nbody.modalOpen {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `LicenseModal_modalTitle__t+0D8`,
	"licenseModal": `LicenseModal_licenseModal__uXs+h`,
	"modalBody": `LicenseModal_modalBody__85adJ`,
	"modalFooter": `LicenseModal_modalFooter__PETnj`,
	"modalOpen": `LicenseModal_modalOpen__fr9S3`
};
export default ___CSS_LOADER_EXPORT___;
