export const INITIAL_DASHBOARD_TAB_DATA = {
  callback: 0,
  connectionRate: 0,
  converstionRate: 0,
  doNotCall: 0,
  email: 0,
  firstAppt: 0,
  hungup: 0,
  noAnswer: 0,
  notInterested: 0,
  referral: 0,
  totalDials: 0,
  totalReaches: 0,
  goalAchieved: 0,
};

export const INITIAL_DASHBOARD_TAB_PROFILE_DATA = {
  activeSince: '',
  fullName: '',
  location: '',
  reportsTo: '',
  avatarLink: '',
};

export const DASHBOARD_STATE = {
  HAS_DATA: 'hasData',
  IS_LOADING: 'isLoading',
  IS_ERROR: 'isError',
};
