import { twMerge } from 'tailwind-merge';

export const ButtonFinish = ({ onClick, className, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        'inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg',
        className,
      )}
      disabled={disabled}
    >
      Finish
    </button>
  );
};
