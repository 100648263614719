import Dashboard from "../../pages/Dashboard";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import {utils, writeFile} from "xlsx";
import SingleReport from "./SingleReport";
import moment from "moment";


const Report = () => {

    const [searchParams] = useSearchParams();
    const campaignId = searchParams.get("campaign");
    const searchTermRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();

    const [searchQuery, setSearchQuery] = useState(
        campaignId ? "campaign=" + campaignId : ""
    );
    const [selected, setSelected] = useState(['65d8a5e45b855336e9ac5d1c']);
    const {isLoading} = useState(false);
    const [records, setRecords] = useState([
        // {
        //     LeadID: "",
        // }
    ]);

    useEffect(() => {
        const today = moment();
        const thisWeekStart = today.clone().startOf('week').format('YYYY-MM-DD');
        const thisWeekEnd = today.clone().endOf('week').format('YYYY-MM-DD');
        startDateRef.current.value = thisWeekStart;
        endDateRef.current.value = thisWeekEnd;
        fetch("/reportsdata", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                startDate:thisWeekStart,
                endDate: thisWeekEnd,

            })

        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    console.log(data);
                    setRecords(data.reportsData.query1);
                });
            }
            else if(response.status === 401){
                window.location.href = "/login";
            }
            else if(response.status === 302){
                window.location.href = "/caller-dashboard";
            }
        });

    }, []);

    const search = (e) => {
        e.preventDefault();
        fetch("/reportsdata", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                startDate: startDateRef.current.value,
                endDate: endDateRef.current.value,
                callerId: searchTermRef.current.value,
            }),
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    console.log(data);
                    setRecords(data.reportsData.query1);
                });
            } else if(response.status === 401){
                window.location.href = "/login";
            }
            else if(response.status === 302){
                window.location.href = "/caller-dashboard";
            }
        });

        // const urlParams = new URLSearchParams();
        // if (searchTermRef.current.value)
        //     urlParams.set("searchTerm", searchTermRef.current.value);
        //
        // if (startDateRef.current.value)
        //     urlParams.set("startDate", startDateRef.current.value);
        //
        // if (endDateRef.current.value)
        //     urlParams.set("endDate", endDateRef.current.value);
        //
        // if (campaignId) urlParams.set("campaign", campaignId);
        //
        // const urlSearchQuery = urlParams.toString();
        //
        // setSearchQuery(urlSearchQuery);
    };

    const exportToCSV = () => {
        var sel = records.filter(e => selected.includes(e.LeadID))
        const worksheet = utils.json_to_sheet(sel);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        writeFile(workbook, "Report.xlsx");
    };


    const handleExporting = (checked, record_id) => {
        if (checked) {
            setSelected([...selected, record_id]);
        } else {
            setSelected(selected.filter((e) => e != record_id))
        }
    };
    const toggleAll = (checked) => {
        if (checked) {
            setSelected(records.map((e) => e.LeadID))
        } else {
            setSelected([])
        }
    }

    return (
        <Dashboard>
            {" "}

            <div className="w-full flex justify-around my-4">
                <form onSubmit={search} className="flex gap-4  md:flex-row">
                    <div className="flex items-center gap-2">
                        <input
                            ref={searchTermRef}
                            type="text"
                            id="searchTerm"
                            placeholder="Caller Name..."
                            className="border rounded-lg p-3 w-full"
                            onChange={(e) => {
                                // console.log(e.target.value)
                                if (e.target.value && startDateRef.current.value && endDateRef.current.value) {
                                    console.log(e.target.value)
                                    search(e);
                                }
                            }}

                        />
                    </div>

                    <div className="flex items-center gap-2">
                        <input
                            type="date"
                            ref={startDateRef}
                            id="startDate"
                            className="border rounded-lg p-3 w-full"
                            onChange={(e) => {
                                if (e.target.value) {
                                    console.log(e.target.value)
                                    search(e);
                                }
                            }}
                            required
                        />
                        <input
                            type="date"
                            ref={endDateRef}
                            id="endDate"
                            className="border rounded-lg p-3 w-full"
                            onChange={(e) => {
                                // console.log(e.target.value)
                                if (e.target.value) {
                                    console.log(e.target.value)
                                    search(e);
                                }
                            }}
                            required
                        />
                    </div>
                    <div className="flex items-center gap-2">
                        <button className="bg-slate-700 text-white p-2 px-3 rounded-lg uppercase hover:opacity-95">
                            Search
                        </button>
                        <button
                            // type="button"
                            onClick={exportToCSV}
                            className="bg-slate-700 text-white p-2 px-3 rounded-lg uppercase hover:opacity-95"
                        >
                            Export to CSV
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full flex flex-col">
                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                {/*{isLoading && <Loading/>}*/}

                                {!isLoading &&
                                    records &&
                                    (records.length > 0 ? (
                                        <table className="min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium dark:border-neutral-500">
                                            <tr className="">
                                                <th scope="col" className="px-6 py-4">
                                                    <input
                                                        id="selection-checkbox"
                                                        type="checkbox"
                                                        onClick={(e) => {
                                                            toggleAll(e.target.checked)
                                                        }}
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    LeadID
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    CallerID
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Appt Type

                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Company Name
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Contact Name
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Contact Title
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Best Phone Number
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Booked
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Scheduled Date
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Scheduled Time
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Contact Address
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    City
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    State
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Zip Code
                                                </th>
                                                <th scope="col" className="px-6 py-4">
                                                    Linkedin Address
                                                </th>
                                                <th>
                                                    Website
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className={"font-semibold"}>
                                            {records.map((item) => {
                                                return (
                                                    <SingleReport
                                                        handleExporting={handleExporting}
                                                        key={item.LeadID}
                                                        checked={selected.includes(item.LeadID)}
                                                        report={item}
                                                    />
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="font-semibold mx-auto my-2">
                                            No reports available
                                        </p>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    );
}

export default Report;
