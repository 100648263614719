export function getTimezoneAbbreviation() {
  const date = new Date();
  const dateAsString = date.toString();
  const timezoneMatch = dateAsString.match(/\(([^)]+)\)$/);
  if (!timezoneMatch) return 'N/A';
  const timezone = timezoneMatch[1];
  const matches = timezone.match(/\b(\w)/g);
  if (!matches) return 'N/A';
  return matches.join('');
}
