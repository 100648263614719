import React, {useState} from 'react'

const CommonDiscard = ({ setIndex, happenedIndex, updateDialoutcomes, handleFinish, setDialOutcomeDetail, handleOpen3 }) => {
    const [doNotCallReason, setDoNotCallReason] = useState("")
    const [finishClicked, setFinishClicked] = useState(false)
    const [isPositioned, setIsPositioned] = useState(false)
    setTimeout(() => setIsPositioned(true), 1000)

    return (
        <>	<div
            class="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
            <h4 class="text-center text-lg font-semibold xl:text-xl">
                Why are you marking the prospect as Do Not Call?
            </h4>

            <div
                class="cursor-pointer mt-6 flex flex-col items-center justify-center gap-4">
                <a
                onClick={() => {setDoNotCallReason("Wrong Contact"); setDialOutcomeDetail("Wrong Contact")}}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === "Wrong Contact" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-out, opacity ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-in-out`,
                    }}>
                    Wrong Contact
                </a>
                <a
                onClick={() => {setDoNotCallReason("Company not in business"); setDialOutcomeDetail("Company not in business")}}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === "Company not in business" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-out, opacity ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-in-out`,
                    }}>
                    Company not in business
                </a>
                <a
                onClick={() => {setDoNotCallReason("Remove from List"); setDialOutcomeDetail("Remove from List")}}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === "Remove from List" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-out, opacity ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-in-out`,
                    }}>
                    Remove from List
                </a>
                <a
                onClick={() => {setDoNotCallReason("Unsuitable company"); setDialOutcomeDetail("Unsuitable company")}}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === "Unsuitable company" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-out, opacity ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-in-out`,
                    }}>
                    Unsuitable company
                </a>
                <a
                onClick={() => {setDoNotCallReason("Current customer"); setDialOutcomeDetail("Current customer")}}
                    class={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === "Current customer" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
                    style={{
                        transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                    }}>
                    Current customer
                </a>
            </div>
        </div>
        <div class="cursor-pointer flex flex-wrap items-center justify-between gap-2">
            <a
                onClick={() => setIndex(1)}
                class="inline-flex items-center gap-x-2 text-sm text-gray-800 transition hover:text-secondary">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    class="h-4 w-4"
                    viewBox="0 0 24 24">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                Go Back
            </a>
            <button
                onClick={() => { 
                    updateDialoutcomes(happenedIndex);
                    setFinishClicked(true);
                    handleFinish();
                }}
                class={`inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg ${doNotCallReason === "" ? "hidden" : ""}`}
                disabled={finishClicked}>
                Finish
            </button>
            <span
                onClick={handleOpen3}
                data-hs-overlay="#call-notes-modal"
                className="cursor-pointer rounded-lg bg-transparent-1 p-1 text-primary">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-6 w-6"
                >
                    <path
                        d="M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z" />
                </svg>
            </span>
        </div>
    </>
    )
}

export default CommonDiscard
