import BasicModal from '../../Common/BasicModel';

export const ReadCallNotesModal = ({
  open,
  onClose,
  selectedRecording,
  getLocalTimeStampForRecording,
  currentCLTranscript,
}) => {
  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="transcript-modal"
        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
      >
        <div className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
          <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
            <div className="flex w-full justify-between">
              <div className="flex">
                <span className="inline-flex justify-start items-center border-transparent">
                  <svg
                    width="15"
                    height="19"
                    viewBox="0 0 15 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.8006 5.32913L10.0278 0.21375C9.96447 0.14591 9.88926 0.0921149 9.80649 0.055439C9.72372 0.0187631 9.63502 -7.52589e-05 9.54545 2.25953e-07H1.36364C1.00198 2.25953e-07 0.655131 0.153983 0.3994 0.428075C0.143668 0.702166 0 1.07391 0 1.46154V17.5385C0 17.9261 0.143668 18.2978 0.3994 18.5719C0.655131 18.846 1.00198 19 1.36364 19H13.6364C13.998 19 14.3449 18.846 14.6006 18.5719C14.8563 18.2978 15 17.9261 15 17.5385V5.84615C15.0001 5.75016 14.9825 5.65509 14.9483 5.56638C14.9141 5.47767 14.8639 5.39705 14.8006 5.32913ZM10.2273 2.49466L12.6724 5.11539H10.2273V2.49466ZM13.6364 17.5385H1.36364V1.46154H8.86364V5.84615C8.86364 6.03997 8.93547 6.22584 9.06334 6.36289C9.1912 6.49993 9.36462 6.57692 9.54545 6.57692H13.6364V17.5385ZM10.9091 10.2308C10.9091 10.4246 10.8373 10.6105 10.7094 10.7475C10.5815 10.8845 10.4081 10.9615 10.2273 10.9615H4.77273C4.5919 10.9615 4.41847 10.8845 4.29061 10.7475C4.16274 10.6105 4.09091 10.4246 4.09091 10.2308C4.09091 10.037 4.16274 9.85108 4.29061 9.71404C4.41847 9.57699 4.5919 9.5 4.77273 9.5H10.2273C10.4081 9.5 10.5815 9.57699 10.7094 9.71404C10.8373 9.85108 10.9091 10.037 10.9091 10.2308ZM10.9091 13.1538C10.9091 13.3477 10.8373 13.5335 10.7094 13.6706C10.5815 13.8076 10.4081 13.8846 10.2273 13.8846H4.77273C4.5919 13.8846 4.41847 13.8076 4.29061 13.6706C4.16274 13.5335 4.09091 13.3477 4.09091 13.1538C4.09091 12.96 4.16274 12.7742 4.29061 12.6371C4.41847 12.5001 4.5919 12.4231 4.77273 12.4231H10.2273C10.4081 12.4231 10.5815 12.5001 10.7094 12.6371C10.8373 12.7742 10.9091 12.96 10.9091 13.1538Z"
                      fill="#0F0F74"
                    />
                  </svg>
                </span>
                <span className="p-2 inline-flex justify-start items-center border-transparent">
                  {selectedRecording.ContactName}
                </span>
                <span className="p-2 inline-flex justify-start items-center border-transparent">
                  {getLocalTimeStampForRecording(
                    selectedRecording.TimeStamp,
                    'MM-DD-YYYY',
                  )}
                </span>
              </div>
              <button
                onClick={onClose}
                className="inline-flex justify-end items-center border-transparent"
                data-hs-overlay="#transcript-modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
            <div className="p-5 bg-clip-text text-gray overflow-y-auto max-h-[340px] [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
              {currentCLTranscript &&
                currentCLTranscript.length > 0 &&
                currentCLTranscript.map((transcript, index) => (
                  <p
                    key={index}
                    className={`${
                      transcript.channel === '1'
                        ? 'text-blue-900'
                        : 'text-gray-500'
                    } mb-2`}
                  >
                    {transcript.channel === '1'
                      ? 'Caller: ' + transcript.text
                      : 'Prospect: ' + transcript.text}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
