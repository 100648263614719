import { Outlet } from 'react-router-dom';

import {
  RecordingsSearchProvider,
  RefreshTokenProvider,
  TwilioDeviceProvider,
} from '.';

export const RootProvider = () => {
  return (
    <RecordingsSearchProvider>
      <RefreshTokenProvider>
        <TwilioDeviceProvider>
          <Outlet />
        </TwilioDeviceProvider>
      </RefreshTokenProvider>
    </RecordingsSearchProvider>
  );
};
