import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {toast, ToastContainer} from "react-toastify";
import Logo from '../../assets/header/login/img/logo.png';
import LogoTo from '../../assets/header/login/img/logo-2.png';

const ResetPassword = ({}) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    let { token } = useParams();

    const handleSendResetPassword = (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.info('Password does not match.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            });
            return;
        }
        fetch('/api/auth/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token, newPassword })
        }).then(response => {
            if (response.status === 200) {
                toast.info('Password reset successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
                console.log('Password reset successfully.');
                window.location.href = '/login';
            } else if (response.status === 404) {
                toast.info('User not found, invalid token.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
            } else {
                toast.info('Password change failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
            }
        })
    }
    useEffect(() => {
        document.title = 'Accelerate - Reset Your Password';
      }, []);

    return (
        <div>
            <ToastContainer/>
            <nav className="header" id="header">
                <div className="c-grid-fluid">
                    <div className="header__wrapper">
                        <div>
                            <div className="logo logo--header"><a class="logo__link" href="/"><img
                                className="logo__image" src={Logo}
                                alt="Logotype"/></a></div>
                        </div>
                    </div>
                </div>
            </nav>
            <main class="main main--start p-sign-in">
                <section class="sign" id="sign">
                    <div class="c-grid">
                        <div class="section__wrapper">
                            <div class="c-section" data-align="center">
                                <div class="c-section__head">
                                    <div class="logo logo--header"><a class="logo__link" href="/"><img
                                        class="logo__image" src={LogoTo}
                                        alt="Logotype"/></a></div>
                                </div>
                                <div class="c-section__body">
                                    <h5 class="c-section__title">Reset Your Password</h5>

                                    <form class="c-form" action="" method="" autocomplete="off" onSubmit={handleSendResetPassword}>
                                        <div class="c-form__field">
                                            <label class="c-form__label" for="newpassword">New Password</label>
                                            <input class="c-form__input" type="password" name="newpassword" id="newpassword" placeholder="Your new password..." 
                                            value={newPassword} 
                                            onChange={(e) => setNewPassword(e.target.value)} required/>
                                        </div>
                                        <div class="c-form__field">
                                            <label class="c-form__label" for="confirmpassword">Confirm Password</label>
                                            <input class="c-form__input" type="password" name="confirmpassword" id="confirmpassword" placeholder="Confirm your password..." value={confirmPassword} 
                                            onChange={(e) => setConfirmPassword(e.target.value)}  required/>
                                        </div>
                                        <div class="c-form__field c-form__field--btn">
                                            <div class="c-btn__wrapper">
                                                <input class="c-btn" type={"submit"}
                                                    data-btn-color="blue"
                                                    data-btn-size="full" data-btn-fw="600"
                                                    data-btn-round="half" value={"Reset"}/>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="flex flex-col items-end p-2"><a href="/login">Go to Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer class="footer" id="footer">
                <div class="c-grid">
                    <div class="footer__wrapper"></div>
                </div>
            </footer>
        </div>
    )
}

export default ResetPassword
