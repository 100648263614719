// import moment from 'moment';
import React, { useEffect, useState } from 'react'
import moment from "moment"
import BasicModal from "../Common/BasicModel"
import ScriptTab from "../CallProcessing/Process/ScriptTab"

const PointerStats = ({ companyWebSite }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const dailyApptStandard = 2;
    const dailyDialStandard = 100;
    const [gptApptResponse, setGptApptResponse] = useState("")
    const [gptDialResponse, setGptDialResponse] = useState("")

    const [myCallStat, setMyCallStat] = useState({
        callback: 0,
        connectionRate: 0,
        converstionRate: 0,
        donotcall: 0,
        email: 0,
        firstAppt: 0,
        hungup: 0,
        noAnswer: 0,
        notInterested: 0,
        referral: 0,
        totalDials: 0,
        totalReaches: 0
    });
    const [teamCallStat, setTeamCallStat] = useState({
        callback: 0,
        connectionRate: 0,
        converstionRate: 0,
        donotcall: 0,
        email: 0,
        firstAppt: 0,
        hungup: 0,
        noAnswer: 0,
        notInterested: 0,
        referral: 0,
        totalDials: 0,
        totalReaches: 0
    });
    const promptForAppt = `
    I am a cold caller and I have not quite reached my goal of 2 booked appointments per day. Offer 1 recommendation on how to book more appointments via cold calling.`;
    const promptForDial = `
    I am a cold caller and I am falling behind on my dials. Write one recommendation on how to increase the number of my dials via cold calling. The response should be separated in paragraph.`;

    useEffect(() => {
        const today = moment();
        const todayFormatted = today.format('YYYY-MM-DD');

        fetch('/call-stats-team', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                startDate: todayFormatted,
                endDate: todayFormatted,
                reportType: "Daily",
            })
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    console.log('Team CallStats', data)
                    if (data.callStats.length > 0) {
                        if (data.callStats[0].CallerOrTeam === 'Team Average') {
                            setTeamCallStat({
                                firstAppt: data.callStats[0].FirstAppt,
                                totalDials: data.callStats[0].TotalDials,
                                callback: data.callStats[0].Callback,
                                connectionRate: data.callStats[0].ConnectionPerc,
                                converstionRate: data.callStats[0].ConversionRate,
                                donotcall: data.callStats[0].DoNotCall,
                                email: data.callStats[0].Email,
                                hungup: data.callStats[0].HangUp,
                                noAnswer: data.callStats[0].NoAns,
                                notInterested: data.callStats[0].NotInterested,
                                referral: data.callStats[0].Referral,
                                totalReaches: data.callStats[0].TotalReaches
                            })
                            if (data.callStats.length > 1) {
                                setMyCallStat({
                                    firstAppt: data.callStats[1].FirstAppt,
                                    totalDials: data.callStats[1].TotalDials,
                                    callback: data.callStats[1].Callback,
                                    connectionRate: data.callStats[1].ConnectionPerc,
                                    converstionRate: data.callStats[1].ConversionRate,
                                    donotcall: data.callStats[1].DoNotCall,
                                    email: data.callStats[1].Email,
                                    hungup: data.callStats[1].HangUp,
                                    noAnswer: data.callStats[1].NoAns,
                                    notInterested: data.callStats[1].NotInterested,
                                    referral: data.callStats[1].Referral,
                                    totalReaches: data.callStats[1].TotalReaches
                                })
                            }
                        } else {
                            setMyCallStat({
                                firstAppt: data.callStats[0].FirstAppt,
                                totalDials: data.callStats[0].TotalDials,
                                callback: data.callStats[0].Callback,
                                connectionRate: data.callStats[0].ConnectionPerc,
                                converstionRate: data.callStats[0].ConversionRate,
                                donotcall: data.callStats[0].DoNotCall,
                                email: data.callStats[0].Email,
                                hungup: data.callStats[0].HangUp,
                                noAnswer: data.callStats[0].NoAns,
                                notInterested: data.callStats[0].NotInterested,
                                referral: data.callStats[0].Referral,
                                totalReaches: data.callStats[0].TotalReaches
                            })
                            if (data.callStats.length > 1) {
                                setTeamCallStat({
                                    firstAppt: data.callStats[1].FirstAppt,
                                    totalDials: data.callStats[1].TotalDials,
                                    callback: data.callStats[1].Callback,
                                    connectionRate: data.callStats[1].ConnectionPerc,
                                    converstionRate: data.callStats[1].ConversionRate,
                                    donotcall: data.callStats[1].DoNotCall,
                                    email: data.callStats[1].Email,
                                    hungup: data.callStats[1].HangUp,
                                    noAnswer: data.callStats[1].NoAns,
                                    notInterested: data.callStats[1].NotInterested,
                                    referral: data.callStats[1].Referral,
                                    totalReaches: data.callStats[1].TotalReaches
                                })
                            }
                        }
                    }
                    fetch('/api/v1/chatgpt', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            message: promptForAppt,
                        })
                    }).then(response => {
                        if (response.status === 200) {
                            response.json().then(data => {
                                setGptApptResponse(data.answer)
                            })
                        }
                        fetch('/api/v1/chatgpt', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                message: promptForDial,
                            })
                        }).then(response => {
                            if (response.status === 200) {
                                response.json().then(data => {
                                    setGptDialResponse(data.answer)
                                })
                            }
                        })
                    })
                })
            } else {
                console.log('Call Stats Retrieve Failed')
            }
        });
    }, []);

    return (
        <>
            <div className="container mx-auto">
                <h6 className='text-primary text-center font-bold py-2'>
                    This is how you currently compare to your team.
                </h6>
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="py-2 px-4"></th>
                            <th className="bg-secondary/50 text-primary font-semibold py-2 px-4 rounded-tl-lg">You</th>
                            <th className="bg-secondary/10 text-primary font-semibold py-2 px-4 rounded-tr-lg">Team</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Appointments</td>
                            {myCallStat.firstAppt < dailyApptStandard ? (
                                <td className="px-4 py-1 h-py-2 bg-secondary/50 text-red-600 font-bold text-center cursor-pointer" onClick={handleOpen}
                                data-hs-overlay="#appt-response-modal">{myCallStat.firstAppt}</td>
                            ) : (
                                <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.firstAppt}</td>
                            )}
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.firstAppt}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Dials</td>
                            {myCallStat.totalDials < dailyDialStandard ? (
                                <td className="px-4 py-1 h-py-2 bg-secondary/50 text-red-600 font-bold text-center cursor-pointer" onClick={handleOpen1}
                                    data-hs-overlay="#dial-response-modal">{myCallStat.totalDials}</td>
                            ) : (
                                <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.totalDials}</td>
                            )}
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.totalDials}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Connection Rate</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{Math.round(myCallStat.connectionRate * 100)}%</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{Math.round(teamCallStat.connectionRate * 100)}%</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Conversion Rate</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{Math.round(myCallStat.converstionRate * 100)}%</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{Math.round(teamCallStat.converstionRate * 100)}%</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Callback</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.callback}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.callback}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Email</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.email}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.email}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">No Answer</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.noAnswer}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.noAnswer}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Hang up</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.hungup}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.hungup}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Not Interested</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{myCallStat.notInterested}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">{teamCallStat.notInterested}</td>
                        </tr>
                        <tr className='h-1.5'>
                            <td className="px-2 py-1 h-py-2 text-primary font-semibold">Do Not Call</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-bl-lg">{myCallStat.donotcall}</td>
                            <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-br-lg">{teamCallStat.donotcall}</td>
                        </tr>
                    </tbody>
                </table>
                <BasicModal open={open} handleClose={handleClose} handleOpen={handleOpen}>
                    <div
                        id="appt-response-modal"
                        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
                    >
                        <div
                            className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border">
                            <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
                                <button onClick={handleClose} className='inline-flex justify-end items-center border-transparent'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                </button>
                                <div className="overflow-y-auto px-5 pb-5">
                                    {gptApptResponse ? (<ScriptTab words={gptApptResponse} />) : (<p>Loading...</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </BasicModal>
                <BasicModal open={open1} handleClose={handleClose1} handleOpen={handleOpen1}>
                    <div
                        id="dial-response-modal"
                        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
                    >
                        <div
                            className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border">
                            <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
                                <button onClick={handleClose1} className='inline-flex justify-end items-center border-transparent'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                </button>
                                <div className="overflow-y-auto px-5 pb-5">
                                    {gptDialResponse ? (<ScriptTab words={gptDialResponse} />) : (<p>Loading...</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </BasicModal>
            </div>
        </>
    )
}

export default PointerStats
