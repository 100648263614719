import BasicModal from '../../Common/BasicModel';

const getFormattedTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const StoplightAnalysisModal = ({
  open,
  onClose,
  selectedRecording,
  getLocalTimeStampForRecording,
  callLength,
  hmmNumber,
  callerTalkTime,
  followedScript,
  analysisScore,
}) => {
  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="spotlight-modal"
        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
      >
        <div className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
          <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
            <div className="flex w-full justify-between">
              <div className="flex">
                <span className="inline-flex justify-start items-center border-transparent">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_432_384)">
                      <path
                        d="M17.25 10.9615H15.75V5.11538H17.25C17.6625 5.11538 18 4.79385 18 4.38462C18 3.97538 17.6625 3.65385 17.25 3.65385H15.75V1.46154C15.75 0.657692 15.0825 0 14.25 0H3.75C2.925 0 2.25 0.657692 2.25 1.46154V3.65385H0.75C0.3375 3.65385 0 3.98269 0 4.38462C0 4.78654 0.3375 5.11538 0.75 5.11538H2.25V10.9615H0.75C0.3375 10.9615 0 11.2904 0 11.6923C0 12.0942 0.3375 12.4231 0.75 12.4231H2.25V17.5385C2.25 18.3496 2.925 19 3.75 19H14.25C15.0825 19 15.75 18.3496 15.75 17.5385V12.4231H17.25C17.6625 12.4231 18 12.1015 18 11.6923C18 11.2831 17.6625 10.9615 17.25 10.9615ZM14.25 17.5385H3.75V1.46154H14.25V17.5385Z"
                        fill="#0F0F74"
                      />
                      <path
                        d="M8.99953 7.20508C7.70203 7.20508 6.64453 8.23546 6.64453 9.49969C6.64453 10.7639 7.69453 11.7943 8.99953 11.7943C10.3045 11.7943 11.3545 10.7712 11.3545 9.49969C11.3545 8.22816 10.3045 7.20508 8.99953 7.20508ZM8.99953 10.647C8.35453 10.647 7.82203 10.1355 7.82203 9.49969C7.82203 8.86392 8.35453 8.35239 8.99953 8.35239C9.64453 8.35239 10.177 8.87123 10.177 9.49969C10.177 10.1282 9.65203 10.647 8.99953 10.647Z"
                        fill="#0F0F74"
                      />
                      <path
                        d="M8.99953 12.3569C7.70203 12.3569 6.64453 13.3873 6.64453 14.6515C6.64453 15.9158 7.69453 16.9462 8.99953 16.9462C10.3045 16.9462 11.3545 15.9231 11.3545 14.6515C11.3545 13.38 10.3045 12.3569 8.99953 12.3569ZM8.99953 15.7989C8.35453 15.7989 7.82203 15.2873 7.82203 14.6515C7.82203 14.0158 8.35453 13.5042 8.99953 13.5042C9.64453 13.5042 10.177 14.0231 10.177 14.6515C10.177 15.28 9.65203 15.7989 8.99953 15.7989Z"
                        fill="#0F0F74"
                      />
                      <path
                        d="M8.99953 2.05322C7.70203 2.05322 6.64453 3.08361 6.64453 4.34784C6.64453 5.61207 7.69453 6.64245 8.99953 6.64245C10.3045 6.64245 11.3545 5.61938 11.3545 4.34784C11.3545 3.0763 10.3045 2.05322 8.99953 2.05322ZM8.99953 5.49515C8.35453 5.49515 7.82203 4.98361 7.82203 4.34784C7.82203 3.71207 8.35453 3.20053 8.99953 3.20053C9.64453 3.20053 10.177 3.71938 10.177 4.34784C10.177 4.9763 9.65203 5.49515 8.99953 5.49515Z"
                        fill="#0F0F74"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_432_384">
                        <rect width="18" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className="p-2 inline-flex font-bold justify-start items-center border-transparent">
                  Stoplight Analysis
                </span>
              </div>
              <button
                onClick={onClose}
                className="inline-flex justify-end items-center border-transparent"
                data-hs-overlay="#spotlight-modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
            <h6 className="flex justify-between text-primary text-center font-semibold px-3 py-3 mb-3">
              <span>
                {selectedRecording.ContactName}, {selectedRecording.CompanyName}
              </span>
              <span>
                {getLocalTimeStampForRecording(
                  selectedRecording.TimeStamp,
                  'MM-DD-YYYY',
                )}
              </span>
            </h6>
            <table className="min-w-full">
              <thead className="hidden">
                <tr>
                  <th className="w-1/3 py-2 px-4"></th>
                  <th className="w-1/3 bg-secondary/50 text-primary font-semibold py-2 px-4 rounded-tl-lg">
                    Analysis
                  </th>
                  <th className="w-1/3 bg-secondary/10 text-primary font-semibold py-2 px-4 rounded-tr-lg">
                    KPI
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Length of Call
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-tl-lg">
                    {getFormattedTime(callLength)}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-tr-lg">
                    <span
                      className={`${
                        callLength < 180
                          ? 'bg-red-500'
                          : callLength > 300
                            ? 'bg-green-500'
                            : 'bg-yellow-500'
                      } inline-block w-4 h-4 rounded-full`}
                    ></span>
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Caller Talk Time
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {callerTalkTime + '%'}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    <span
                      className={`${
                        callerTalkTime > 40 && callerTalkTime < 60
                          ? 'bg-green-500'
                          : callerTalkTime > 80 || callerTalkTime < 20
                            ? 'bg-red-500'
                            : 'bg-yellow-500'
                      } inline-block w-4 h-4 rounded-full`}
                    ></span>
                  </td>
                </tr>
                <tr className="h-1.5 hidden">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Followed Script
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">
                    {followedScript + '%'}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                    <span
                      className={`${
                        followedScript < 50
                          ? 'bg-red-500'
                          : followedScript > 80
                            ? 'bg-green-500'
                            : 'bg-yellow-500'
                      } inline-block w-4 h-4 rounded-full`}
                    ></span>
                  </td>
                </tr>
                <tr className="h-1.5">
                  <td className="px-2 py-1 h-py-2 text-primary font-semibold">
                    Filler Words
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-bl-lg">
                    {hmmNumber}
                  </td>
                  <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-br-lg">
                    <span
                      className={`${
                        hmmNumber < 4
                          ? 'bg-green-500'
                          : hmmNumber > 7
                            ? 'bg-red-500'
                            : 'bg-yellow-500'
                      } inline-block w-4 h-4 rounded-full`}
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 className="text-primary text-center font-bold py-2 hidden">
              Analysis Score:{' '}
              <span className="text-blue-600 font-bold">
                {analysisScore.toFixed(0)}
              </span>
            </h6>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
