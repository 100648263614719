import BasicModal from '../../Common/BasicModel';

export const HaveNoLeadsModal = ({ open, onClose }) => {
  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="out-of-lead-modal"
        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[50%] top-[35%] -translate-x-[50%]"
      >
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <button
              onClick={onClose}
              className="inline-flex justify-end items-center border-transparent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
            <div className="overflow-y-auto text-xl px-5 pb-5">
              <p>
                You have no leads available to call during this hour. Check back
                later, update your ICP or feel free to send a message to{' '}
                <a
                  className="text-secondary"
                  href="mailto:customersuccess@prospectiq.ai"
                >
                  customersuccess@prospectiq.ai
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
