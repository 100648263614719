import moment from 'moment/moment';

import { DASHBOARD_STATE, DATE_TIME_FORMAT } from '../constants';

export const getDashboardState = (isLoading, isError) => {
  if (isLoading) return DASHBOARD_STATE.IS_LOADING;
  if (isError) return DASHBOARD_STATE.IS_ERROR;
  return DASHBOARD_STATE.HAS_DATA;
};

export const getDashboardQueryBody = tabIndex => {
  const currentDate = moment();

  return {
    0: {
      startDate: currentDate.format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      endDate: currentDate.format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      reportType: 'daily',
    },
    1: {
      startDate: currentDate
        .clone()
        .startOf('week')
        .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      endDate: currentDate
        .clone()
        .endOf('week')
        .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      reportType: 'weekly',
    },
    2: {
      startDate: currentDate
        .clone()
        .startOf('month')
        .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      endDate: currentDate
        .clone()
        .endOf('month')
        .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH),
      reportType: 'monthly',
    },
  }[tabIndex];
};
