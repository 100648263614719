import { useState } from 'react';

import { ButtonCallNotes, ButtonFinish, ButtonGoBack } from '../Shared';

const CommonDiscard = ({
  setIndex,
  happenedIndex,
  updateDialoutcomes,
  setDialOutcomeDetail,
  handleOpen3,
  formCallNotes,
}) => {
  const [doNotCallReason, setDoNotCallReason] = useState('');
  const [finishClicked, setFinishClicked] = useState(false);
  const [isPositioned, setIsPositioned] = useState(false);
  setTimeout(() => setIsPositioned(true), 1000);

  return (
    <>
      {' '}
      <div className="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <h4 className="text-center text-lg font-semibold xl:text-xl">
          Why are you marking the prospect as Do Not Call?
        </h4>

        <div className="cursor-pointer mt-6 flex flex-col items-center justify-center gap-4">
          <a
            onClick={() => {
              setDoNotCallReason('Wrong Contact');
              setDialOutcomeDetail('Wrong Contact');
            }}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === 'Wrong Contact' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-out, opacity ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-in-out`,
            }}
          >
            Wrong Contact
          </a>
          <a
            onClick={() => {
              setDoNotCallReason('Company not in business');
              setDialOutcomeDetail('Company not in business');
            }}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === 'Company not in business' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-out, opacity ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-in-out`,
            }}
          >
            Company not in business
          </a>
          <a
            onClick={() => {
              setDoNotCallReason('Remove from List');
              setDialOutcomeDetail('Remove from List');
            }}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === 'Remove from List' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-out, opacity ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-in-out`,
            }}
          >
            Remove from List
          </a>
          <a
            onClick={() => {
              setDoNotCallReason('Unsuitable company');
              setDialOutcomeDetail('Unsuitable company');
            }}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === 'Unsuitable company' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-out, opacity ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-in-out`,
            }}
          >
            Unsuitable company
          </a>
          <a
            onClick={() => {
              setDoNotCallReason('Current customer');
              setDialOutcomeDetail('Current customer');
            }}
            className={`inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50  ${doNotCallReason === 'Current customer' ? 'ring ring-primary/20 border-accent-2 bg-accent-2' : ''} transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
            style={{
              transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
            }}
          >
            Current customer
          </a>
        </div>
      </div>
      <div className="cursor-pointer flex flex-wrap items-center justify-between gap-2">
        <ButtonGoBack
          onClick={() => {
            setIndex(1);
          }}
        />
        <ButtonFinish
          onClick={() => {
            updateDialoutcomes(happenedIndex);
            setFinishClicked(true);
          }}
          disabled={finishClicked}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </>
  );
};

export default CommonDiscard;
