import styles from './LicenseModal.module.css';

const MODAL_WIDTH = '100%';
const MODAL_HEIGHT = '280px';
const LICENSE_AGREEMENT_URL = '/assets/license_agreement_v.2.0.0.html';

export const LicenseModal = ({ onSuccess, onCancel }) => {
  return (
    <div className={styles.licenseModal}>
      <h2 className={styles.modalTitle}>
        ProspectIQ.ai Software-as-a-Service (SaaS) Agreement – terms &
        conditions
      </h2>

      <div className={styles.modalBody}>
        <iframe
          src={LICENSE_AGREEMENT_URL}
          width={MODAL_WIDTH}
          height={MODAL_HEIGHT}
          style={{ border: 'none' }}
          title="License Agreement"
        />
      </div>

      <div className={styles.modalFooter}>
        <button className="c-btn" data-btn-color="blue" onClick={onSuccess}>
          I Agree
        </button>

        <button className="c-btn" data-btn-color="gray" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};
