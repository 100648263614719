import { useContext } from 'react';

import { TwilioDeviceContext } from '../context';

export const useTwilioDeviceContext = () => {
  const twilioDeviceContext = useContext(TwilioDeviceContext);

  if (!twilioDeviceContext)
    throw new Error('[TwilioDeviceContext]: unexpected error');

  return twilioDeviceContext;
};
