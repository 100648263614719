import { Button, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const STATE = {
  HAS_OBJECTIONS: 'hasObjections',
  HAS_NO_OBJECTIONS: 'hasNoObjections',
  ARE_OBJECTIONS_LOADING: 'areObjectionsLoading',
  ARE_OBJECTIONS_ERROR: 'areObjectionsError',
};

const getState = (objections, areObjectionsLoading, areObjectionsError) => {
  if (areObjectionsLoading) return STATE.ARE_OBJECTIONS_LOADING;
  if (areObjectionsError) return STATE.ARE_OBJECTIONS_ERROR;
  const hasNoObjections = objections.length === 0;
  if (hasNoObjections) return STATE.HAS_NO_OBJECTIONS;
  return STATE.HAS_OBJECTIONS;
};

export const Objections = ({
  setIsGPTForObj,
  handleObjectionSelect,
  handleGetGPTResponse,
  setIsSubBubbleUp,
}) => {
  const [objections, setObjections] = useState([]);
  const [areObjectionsLoading, setAreObjectionsLoading] = useState(true);
  const [areObjectionsError, setAreObjectionsError] = useState(false);
  const [isBubbleUp, setIsBubbleUp] = useState(false);

  const handleObjectionClick = objection => {
    setIsGPTForObj(false);
    handleObjectionSelect(objection);
    handleGetGPTResponse(
      objection.MainObjectionResponse,
      objection.MainObjection,
    );
    setTimeout(() => setIsSubBubbleUp(true), 2000);
  };

  const fetchObjections = useCallback(async () => {
    setAreObjectionsLoading(true);
    setAreObjectionsError(false);

    try {
      const response = await fetch('/api/v1/pitch/objections', {
        method: 'POST',
      });

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      setObjections(data.objections);
      setAreObjectionsLoading(false);
      setTimeout(() => setIsBubbleUp(true), 500);
    } catch {
      setAreObjectionsLoading(false);
      setAreObjectionsError(true);
      toast.error('Failed to load objections!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, []);

  useEffect(() => {
    fetchObjections();
  }, [fetchObjections]);

  const state = getState(objections, areObjectionsLoading, areObjectionsError);

  if (state === STATE.ARE_OBJECTIONS_LOADING) {
    return (
      <div className="flex justify-center items-center flex-1">
        <CircularProgress size={80} sx={{ color: '#1F68A2' }} />
      </div>
    );
  }

  if (state === STATE.ARE_OBJECTIONS_ERROR) {
    return (
      <div className="flex justify-center items-center flex-1 flex-col gap-6">
        <div className="flex justify-center items-center flex-col gap-3">
          <span className="text-2xl font-medium">
            Failed to load objections
          </span>
          <span className="text-lg font-medium text-[#6b7280]">
            Error occurred while loading. Try again.
          </span>
        </div>
        <Button
          disableFocusRipple
          disableRipple
          variant="text"
          sx={{
            color: '#FF344C',
            fontFamily: 'inherit',
            textTransform: 'none',
            fontSize: 21,
            padding: 0,
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'transparent',
              color: '#FF5A6D',
            },
          }}
          onClick={fetchObjections}
        >
          Reload
        </Button>
      </div>
    );
  }

  if (state === STATE.HAS_NO_OBJECTIONS) {
    return (
      <div className="flex justify-center items-center flex-1">
        <p>There are no objections to display</p>
      </div>
    );
  }

  return (
    <div className="h-full items-center grid grid-cols-2 gap-0 h-gap-3">
      {objections.map((objection, idx) => {
        const className = `flex-col inline-flex min-w-24 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent p-3 text-center text-sm font-medium leading-none text-accent-2 hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:min-w-32 2xl:text-base mr-2 mb-5 cursor-pointer duration-1000 ${
          isBubbleUp
            ? 'translate-y-0 opacity-100'
            : 'translate-y-full opacity-0'
        }`;
        const style = {
          transition: `transform ${1.5 - idx * 0.1}s ${
            idx * 0.2
          }s ease-out, opacity ${1.5 - idx * 0.1}s ${idx * 0.2}s ease-in-out`,
        };

        return (
          <div
            key={idx}
            className={className}
            style={style}
            onClick={() => {
              handleObjectionClick(objection);
            }}
          >
            {objection.MainObjection}
          </div>
        );
      })}
    </div>
  );
};
