// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




#animateButton {
	position: absolute;
	top:0px;
	transition: transform 0.5s ease-in-out;
  }
  
  #animateButton2 {
	position: absolute;
	top:60px;
	transition: transform 0.5s ease-in-out;
  }
  
  #animateButton3 {
	position: absolute;
	top:120px;
	transition: transform 0.5s ease-in-out;
  }
  
  #animateButton4 {
	position: absolute;  
	top:180px;
	transition: transform 0.5s ease-in-out;
  }
  
  .myclass{
	position: relative;
	top:100px;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/tailwind.css"],"names":[],"mappings":";;;;;AAKA;CACC,kBAAkB;CAClB,OAAO;CACP,sCAAsC;EACrC;;EAEA;CACD,kBAAkB;CAClB,QAAQ;CACR,sCAAsC;EACrC;;EAEA;CACD,kBAAkB;CAClB,SAAS;CACT,sCAAsC;EACrC;;EAEA;CACD,kBAAkB;CAClB,SAAS;CACT,sCAAsC;EACrC;;EAEA;CACD,kBAAkB;CAClB,SAAS;EACR","sourcesContent":["\n\n\n\n\n#animateButton {\n\tposition: absolute;\n\ttop:0px;\n\ttransition: transform 0.5s ease-in-out;\n  }\n  \n  #animateButton2 {\n\tposition: absolute;\n\ttop:60px;\n\ttransition: transform 0.5s ease-in-out;\n  }\n  \n  #animateButton3 {\n\tposition: absolute;\n\ttop:120px;\n\ttransition: transform 0.5s ease-in-out;\n  }\n  \n  #animateButton4 {\n\tposition: absolute;  \n\ttop:180px;\n\ttransition: transform 0.5s ease-in-out;\n  }\n  \n  .myclass{\n\tposition: relative;\n\ttop:100px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
