import { useContext } from 'react';

import { UserRoleContext } from '../context';

export const useUserRoleContext = () => {
  const userRoleContext = useContext(UserRoleContext);

  if (!userRoleContext) throw new Error('[UserRoleContext]: unexpected error');

  return userRoleContext;
};
