import { useContext } from 'react';

import { RecordingsSearchContext } from '../context';

export const useRecordingsSearchContext = () => {
  const recordingsSearchContext = useContext(RecordingsSearchContext);

  if (!recordingsSearchContext)
    throw new Error('[RecordingsSearchContext]: unexpected error');

  return recordingsSearchContext;
};
