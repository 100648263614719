import * as Sentry from '@sentry/react';
import { Device } from '@twilio/voice-sdk';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { TWILIO_CONFIG } from '../constants';
import { TwilioDeviceContext } from '../context';

const fetchTwilioDeviceToken = async () => {
  try {
    const response = await fetch('/token');

    if (response.status !== 200) throw new Error();

    const data = await response.json();
    return data.token;
  } catch {
    return false;
  }
};

export const TwilioDeviceProvider = ({ children }) => {
  const [twilioDevice, setTwilioDevice] = useState(null);

  const createTwilioDevice = useCallback(async () => {
    if (twilioDevice) return;

    try {
      const token = await fetchTwilioDeviceToken();
      if (!token) throw new Error();
      const device = new Device(token, TWILIO_CONFIG.DEVICE_OPTIONS);
      await device.register();
      device.on('tokenWillExpire', async () => {
        try {
          const token = await fetchTwilioDeviceToken();
          if (!token) throw new Error();
          device.updateToken(token);
        } catch {
          // TODO: add error handler
        }
      });
      device.on('error', error => {
        Sentry.captureException(new Error(error));
        const errorMessage =
          error?.message ?? TWILIO_CONFIG.DEFAULT_ERROR_MESSAGE;
        toast.info(errorMessage, {
          position: 'top-right',
          autoClose: 3000,
        });
      });
      setTwilioDevice(device);
    } catch {
      // TODO: add error handler
    }
  }, [twilioDevice]);

  useEffect(() => {
    createTwilioDevice();

    return () => {
      if (!twilioDevice) return;
      twilioDevice.removeAllListeners();
      twilioDevice.destroy();
      setTwilioDevice(null);
    };
  }, [twilioDevice, createTwilioDevice]);

  return (
    <TwilioDeviceContext.Provider value={{ twilioDevice }}>
      {children}
    </TwilioDeviceContext.Provider>
  );
};
