const TEN_MIN_IN_MS = 10 * 60 * 1000;

export const TWILIO_CONFIG = {
  DEFAULT_ERROR_MESSAGE: 'Call disconnected. Please try again!',
  DEVICE_OPTIONS: {
    codecPreferences: ['opus', 'pcmu'],
    logLevel: 4,
    tokenRefreshMs: TEN_MIN_IN_MS,
    enableImprovedSignalingErrorPrecision: true,
  },
};
