import moment from 'moment';
import { useEffect } from 'react';

import {
  DATE_TIME_FORMAT,
  DEFAULT_MAX_APPOINTMENT_TIME,
  DEFAULT_MIN_APPOINTMENT_TIME,
  getInitialAppointmentTime,
} from '../../constants';
import { ButtonCallNotes, ButtonGoBack, ButtonNext } from '../Shared';

const activeClass =
  'time-button m-px flex h-10 w-full items-center justify-center rounded-md border border-transparent bg-blue-600 text-lg font-medium font-medium text-white hover:border-blue-600 disabled:pointer-events-none disabled:text-gray-300 dark:bg-blue-500 dark:hover:border-gray-700';
const dActiveClass =
  'rounded-md bg-secondary/10 px-4 py-2 font-medium transition hover:bg-secondary/30 md:text-lg';

const Schedule2 = ({
  setIndex,
  onChangeSelectedTime,
  setFormObjContact,
  formObjContact,
  selectedTime,
  happenedIndex,
  handleOpen3,
  selectedDate,
  formCallNotes,
}) => {
  const currentDate = moment();
  const isToday = selectedDate.isSame(currentDate, 'day');
  const getPeriods = () => {
    const timeLists = [
      DEFAULT_MIN_APPOINTMENT_TIME,
      DEFAULT_MAX_APPOINTMENT_TIME,
    ];
    const startTime = moment(timeLists[0], DATE_TIME_FORMAT.h_mm_A);
    const endTime = moment(timeLists[1], DATE_TIME_FORMAT.h_mm_A);

    const allTimes = [];

    let currentTime = startTime;
    while (currentTime.isSameOrBefore(endTime)) {
      allTimes.push(currentTime.format(DATE_TIME_FORMAT.h_mm_A));
      currentTime.add(30, 'minutes'); // Change this value to adjust the interval between times
    }
    return allTimes;
  };
  const activeButton = (e, time) => {
    e.target.className = activeClass;
    const buttons = document.querySelectorAll('.time-button');
    for (var i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      if (button.textContent === time) {
        button.className = activeClass;
      } else {
        button.className = dActiveClass;
      }
    }
    onChangeSelectedTime(time);
    setFormObjContact({ ...formObjContact, apptTime: `${time} EST` });
  };

  useEffect(() => {
    if (selectedTime) return;
    onChangeSelectedTime(getInitialAppointmentTime(isToday));
  }, [selectedTime, isToday, onChangeSelectedTime]);
  const isNextButtonDisabled = !selectedTime;
  return (
    <>
      <div className="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 md:p-10 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <div className="">
          <p className="text-center text-base font-medium lg:text-lg xl:text-xl">
            What time did you schedule the appointment for?
          </p>
          {/* <!-- Times --> */}
          <div className="mt-6 grid grid-cols-[repeat(auto-fit,minmax(120px,1fr))] gap-2 lg:gap-4">
            {[...getPeriods()].map((time, index) => {
              const isBefore = moment(time, DATE_TIME_FORMAT.h_mm_A).isBefore(
                moment(currentDate, DATE_TIME_FORMAT.h_mm_A),
              );
              const isDisabled = isToday && isBefore;
              return (
                <button
                  key={index}
                  onClick={e => activeButton(e, time)}
                  type="button"
                  disabled={isDisabled}
                  className={selectedTime == time ? activeClass : dActiveClass}
                >
                  {time}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-2">
        <ButtonGoBack
          onClick={() => {
            setIndex(2);
            onChangeSelectedTime();
          }}
        />
        <ButtonNext
          onClick={
            isNextButtonDisabled
              ? undefined
              : () => setIndex(happenedIndex === 0 ? 19 : 4)
          }
          disabled={isNextButtonDisabled}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </>
  );
};

export default Schedule2;
