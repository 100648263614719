import { useState } from 'react';
import { toast } from 'react-toastify';

const PRACTICE_STYLES = [
  {
    id: 1,
    title: 'Agreeable',
    phoneNumber: '+17275940759',
    imgSrc: '/avatar/practice-1.png',
    backgroundColor: 'rgb(233, 232, 247)',
    additionalClassName: '-translate-x-full',
  },
  {
    id: 2,
    title: 'Skeptical',
    phoneNumber: '+17275940837',
    imgSrc: '/avatar/practice-2.png',
    backgroundColor: 'rgb(222, 234, 245)',
    additionalClassName: 'translate-x-full',
  },
  {
    id: 3,
    title: 'Difficult',
    phoneNumber: '+17275940765',
    imgSrc: '/avatar/practice-3.png',
    backgroundColor: 'rgb(239, 239, 227)',
    additionalClassName: '-translate-x-full',
  },
];

const Practice = ({
  initiatePracticeCall,
  setPracticeCallDialed,
  practiceCallDialed,
}) => {
  const [isCentered, setIsCentered] = useState(false);
  setTimeout(() => setIsCentered(true), 0);

  const initiateCall = phoneNumber => {
    // TODO: Revert the functionality back once we fix the error "A Call is already active"
    toast.info('Service Temporarily Unavailable', {
      position: 'top-right',
      autoClose: 3000,
    });
    // if (practiceCallDialed) return;
    // initiatePracticeCall(phoneNumber);
    // setPracticeCallDialed(true);
  };

  return (
    <div className="rounded-xl m-auto round">
      <h6 className="text-center text-lg font-semibold py-3">
        What style do you want practice?
      </h6>
      <div className="items-center">
        <div className="text-center">
          {PRACTICE_STYLES.map(
            ({
              id,
              title,
              phoneNumber,
              imgSrc,
              backgroundColor,
              additionalClassName,
            }) => {
              const className = `flex cursor-pointer items-center my-5 rounded-full transform transition-all duration-500 ease-in-out ${
                isCentered ? 'translate-x-0' : additionalClassName
              }`;

              return (
                <div
                  key={id}
                  onClick={() => {
                    initiateCall(phoneNumber);
                  }}
                  style={{ backgroundColor }}
                  className={className}
                  data-hs-overlay="#dial-modal"
                >
                  <img
                    src={imgSrc}
                    alt="img-agreeable"
                    className="rounded-full h-16 w-16"
                  />
                  <span className="text-lg px-10 font-bold text-blue-900">
                    {title}
                  </span>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default Practice;
