import { twMerge } from 'tailwind-merge';

export const ButtonGoBack = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        'inline-flex items-center gap-x-2 text-sm text-gray-800 transition hover:text-secondary',
        className,
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        className="h-4 w-4"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
        />
      </svg>
      Go Back
    </button>
  );
};
