import React, { useState } from 'react';
import Datepicker from "react-tailwindcss-datepicker"
const CallSearchBox = ({setCallRecList}) => {
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchType, setSearchType] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState([new Date(), new Date()]);
    const [animationClass, setAnimationClass] = useState('');
    const [value, setValue] = useState({
        startDate: null, endDate: null
    });

    const handleSearch = (parameter, searchTerm) =>{
        let requestBody = {
            [parameter]: searchTerm
        }
        if(parameter === 'dateRange'){
            requestBody = {
                DateFrom: searchTerm.startDate,
                DateTo: searchTerm.endDate
            }
        }
        fetch('/call-recordings',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)


        }).then(res => res.json().then(data => {
            console.log(data);
            setCallRecList(data.callRecordings.query1);

        }))

    }

    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        handleSearch('dateRange', newValue);
        setValue(newValue);
    }

    const handleIconClick = () => {
        if (searchToggle) {
            setAnimationClass('');
            setSearchToggle(false);
        } else {
            setAnimationClass('move-left');
            setSearchToggle(true);
        }
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setInputValue('');
    };

    const renderInputField = () => {
        switch (searchType) {
            case 'Contact Name':
                return <input type="text" className='inline-flex focus:outline-none bg-transparent border-b-2 border-secondary/20' placeholder='Type here...' value={inputValue} onChange={e => {setInputValue(e.target.value);
                    handleSearch('ContactName', e.target.value);
                }} />;
            case 'Outcome':
                return (
                    <select value={inputValue} onChange={e => {setInputValue(e.target.value);
                    handleSearch('callType', e.target.value);
                    }} className='inline-flex focus:outline-none bg-transparent border-b-2 border-secondary/20 ml-2'>
                        <option value="">Select</option>
                        <option value="NoAns">No Answer</option>
                        <option value="FirstAppt">Booked</option>
                        <option value="Callback">Callback</option>
                        <option value="NotInterested">Not Interested</option>
                        <option value="EmailSent">Email</option>
                        <option value="DoNotCall">Do Not Call</option>
                        <option value="HangUp">Hung Up</option>

                    </select>
                );
            case 'Date range':
                return (
                    <div id='date-pick-div'>
                        <Datepicker
                        useRange={false}
                        value={value}
                        onChange={handleValueChange}
                        primaryColor={"indigo"}
                        inputClassName={`rounded-md focus:ring-0 font-normal focus:outline-none bg-transparent border-b-2 border-secondary/20`}
                        displayFormat={"DD/MM/YY"}
                        placeholder='Select Dates'
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="search-component relative p-2 flex items-center">
            <div className={`search-icon p-1 ${animationClass}`} onClick={handleIconClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            </div>
            {searchToggle && (
                <div className="search-inputs inline-flex">
                    <label>{!searchType && 'Search by:'}</label>
                    <select value={searchType} onChange={handleSearchTypeChange} className='inline-flex focus:outline-none bg-transparent border-b-2 border-secondary/20 ml-2'>
                        <option value="">Select</option>
                        <option value="Contact Name">Contact Name</option>
                        <option value="Outcome">Outcome</option>
                        <option value="Date range">Date range</option>
                    </select>
                    {renderInputField()}
                </div>
            )}
        </div>
    );
}

export default CallSearchBox;
