import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { utils, writeFile } from 'xlsx';

import { ErrorMessage } from '../../components/Shared';
import { DATE_TIME_FORMAT, VALIDATION_SCHEMAS } from '../../constants';
import { ManagerLayout } from '../../layouts';

const CURRENT_DATE = moment();
const START_DATE = CURRENT_DATE.clone()
  .startOf('week')
  .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH);
const END_DATE = CURRENT_DATE.clone()
  .endOf('week')
  .format(DATE_TIME_FORMAT.YYYY_MM_DD_DASH);
const DEFAULT_VALUES = {
  callerId: '',
  startDate: START_DATE,
  endDate: END_DATE,
};
const CELLS = [
  'LeadID',
  'CallerID',
  'Appt Type',
  'Company Name',
  'Contact Name',
  'Contact Title',
  'Best Phone Number',
  'Email',
  'Booked',
  'Scheduled Date',
  'Scheduled Time',
  'Contact Address',
  'City',
  'State',
  'Zip Code',
  'Linkedin Address',
  'Website',
];
const STATE = {
  HAS_REPORTS: 'hasReports',
  HAS_NO_REPORTS: 'hasNoReports',
  ARE_REPORTS_LOADING: 'areReportsLoading',
  ARE_REPORTS_ERROR: 'areReportsError',
};

const getState = (reports, areReportsLoading, areReportsError) => {
  if (areReportsLoading) return STATE.ARE_REPORTS_LOADING;
  if (areReportsError) return STATE.ARE_REPORTS_ERROR;
  const hasNoReports = reports.length === 0;
  if (hasNoReports) return STATE.HAS_NO_REPORTS;
  return STATE.HAS_REPORTS;
};

const getLocalTimeStampForRecording = (timestamp, format) => {
  // Get the current time in the local time zone
  const localTime = moment();
  // Get the current time in the America/Chicago time zone
  const chicagoTime = moment.tz('America/Chicago');
  // Calculate the difference in hours
  const timeDiff = localTime.utcOffset() - chicagoTime.utcOffset();
  const centralTime = moment.utc(timestamp); // Because SQL server is at CDT/CST
  const localUserTime = centralTime.clone().add(timeDiff, 'minutes');

  return localUserTime.format(format);
};

const convertToUserLocalTime = (dateStr, format = 'MMMM Do, YYYY h:mm A z') => {
  // Determine the user's local timezone
  let appendedOffset = dateStr.replace('.000Z', '-05:00');
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to user's timezone and format it
  return moment(appendedOffset).tz(userTimezone).format(format);
};

export const Report = () => {
  const [reports, setReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [areReportsLoading, setAreReportsLoading] = useState(true);
  const [areReportsError, setAreReportsError] = useState(false);

  const selectedReportsMap = selectedReports.reduce((acc, { LeadID }) => {
    acc[LeadID] = LeadID;
    return acc;
  }, {});

  const {
    control,
    formState: { isDirty, isValid },
    reset,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.SEARCH_REPORTS),
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const onSelectAllReports = checked => {
    setSelectedReports(checked ? reports : []);
  };

  const onSelectReport = selectedReport => {
    const selected = !!selectedReportsMap[selectedReport.LeadID];
    selected
      ? setSelectedReports(prev =>
          prev.filter(report => report.LeadID !== selectedReport.LeadID),
        )
      : setSelectedReports(prev => [...prev, selectedReport]);
  };

  const onExportSelectedReports = () => {
    const worksheet = utils.json_to_sheet(selectedReports);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, 'Report.xlsx');
    toast.success('Success', {
      position: 'top-right',
      autoClose: 3000,
    });
    setSelectedReports([]);
  };

  const fetchReports = useCallback(
    async formData => {
      setSelectedReports([]);
      setAreReportsLoading(true);
      setAreReportsError(false);

      try {
        const response = await fetch('/reportsdata', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });

        if (response.status !== 200) throw new Error();

        const data = await response.json();
        setReports(data?.reportsData?.query1 ?? []);
        reset(formData);
        setAreReportsLoading(false);
      } catch {
        setAreReportsLoading(false);
        setAreReportsError(true);
        toast.error('Failed to load reports!', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    },
    [reset],
  );

  useEffect(() => {
    handleSubmit(fetchReports)();
  }, [fetchReports, handleSubmit]);

  const isAllReportsSelected = selectedReports.length === reports.length;
  const isInputDisabled = areReportsLoading;
  const isSearchButtonDisabled = !isDirty || !isValid || areReportsLoading;
  const isExportButtonDisabled =
    areReportsLoading || selectedReports.length === 0;
  const startDateWatch = watch('startDate');
  const endDateWatch = watch('endDate');
  const state = getState(reports, areReportsLoading, areReportsError);

  return (
    <ManagerLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="w-full flex justify-around my-4">
          <form
            onSubmit={handleSubmit(fetchReports)}
            className="flex gap-4 md:flex-row"
          >
            <Controller
              control={control}
              name="callerId"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                const hasErrorMessage = !!error?.message;
                const borderColor = hasErrorMessage
                  ? 'border-red-500/20 focus:border-red-500'
                  : 'border-secondary/20 focus:border-blue-500';
                const inputClassName = `${borderColor} border rounded-lg p-3 w-full focus:outline-none`;

                return (
                  <div className="flex items-center gap-2 relative">
                    <input
                      placeholder="Caller Name..."
                      className={inputClassName}
                      onChange={onChange}
                      value={value}
                      disabled={isInputDisabled}
                    />
                    {hasErrorMessage && (
                      <div className="absolute -bottom-4">
                        <ErrorMessage errorMessage={error.message} />
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <div className="flex items-center gap-2">
              <Controller
                control={control}
                name="startDate"
                render={({ field: { value, onChange } }) => (
                  <input
                    type="date"
                    className="border rounded-lg p-3 w-full focus:outline-none border-secondary/20 focus:border-blue-500"
                    onChange={onChange}
                    value={value}
                    disabled={isInputDisabled}
                    max={endDateWatch}
                  />
                )}
              />
              <Controller
                control={control}
                name="endDate"
                render={({ field: { value, onChange } }) => (
                  <input
                    type="date"
                    className="border rounded-lg p-3 w-full focus:outline-none border-secondary/20 focus:border-blue-500"
                    onChange={onChange}
                    value={value}
                    disabled={isInputDisabled}
                    min={startDateWatch}
                  />
                )}
              />
            </div>
            <div className="flex items-center gap-2">
              <button
                type="submit"
                disabled={isSearchButtonDisabled}
                className="!bg-slate-700 text-white p-2 px-3 rounded-lg uppercase hover:opacity-95 disabled:pointer-events-none"
              >
                Search
              </button>
              <button
                type="button"
                disabled={isExportButtonDisabled}
                className="!bg-slate-700 text-white p-2 px-3 rounded-lg uppercase hover:opacity-95 disabled:pointer-events-none"
                onClick={onExportSelectedReports}
              >
                Export to CSV
              </button>
            </div>
          </form>
        </div>
        {state === STATE.ARE_REPORTS_LOADING && (
          <div className="flex justify-center items-center flex-1">
            <CircularProgress size={80} sx={{ color: '#1F68A2' }} />
          </div>
        )}
        {state === STATE.ARE_REPORTS_ERROR && (
          <div className="flex justify-center items-center flex-1 flex-col gap-6">
            <div className="flex justify-center items-center flex-col gap-3">
              <span className="text-2xl font-medium">
                Failed to load reports
              </span>
              <span className="text-lg font-medium text-[#6b7280]">
                Error occurred while loading. Try again.
              </span>
            </div>
            <Button
              disableFocusRipple
              disableRipple
              variant="text"
              sx={{
                color: '#FF344C',
                fontFamily: 'inherit',
                textTransform: 'none',
                fontSize: 21,
                padding: 0,
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'transparent',
                  color: '#FF5A6D',
                },
              }}
              onClick={() => {
                handleSubmit(fetchReports)();
              }}
            >
              Reload
            </Button>
          </div>
        )}
        {state === STATE.HAS_NO_REPORTS && (
          <div className="flex justify-center items-center flex-1">
            <p>There are no reports to display</p>
          </div>
        )}
        {state === STATE.HAS_REPORTS && (
          <div className="flex-1 overflow-x-auto overflow-y-auto">
            <table className="min-w-full text-left text-sm font-light overflow-auto">
              <thead className="sticky top-0 bg-neutral-100 border-b font-medium dark:border-neutral-500">
                <tr>
                  <th scope="col" className="px-6 py-2">
                    <input
                      checked={isAllReportsSelected}
                      type="checkbox"
                      onChange={e => {
                        onSelectAllReports(e.target.checked);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </th>
                  {CELLS.map(v => (
                    <th
                      key={v}
                      scope="col"
                      className="px-6 py-2 whitespace-nowrap"
                    >
                      {v}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="font-semibold">
                {reports.map((report, idx) => {
                  const withBorderBottom = idx !== reports.length - 1;
                  const checked = !!selectedReportsMap[report.LeadID];

                  return (
                    <tr
                      key={report.LeadID}
                      className={
                        withBorderBottom
                          ? 'border-b dark:border-neutral-500'
                          : undefined
                      }
                    >
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        <input
                          id="selection-checkbox"
                          type="checkbox"
                          checked={checked}
                          onChange={() => {
                            onSelectReport(report);
                          }}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.LeadID}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.CallerID}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report.ApptType}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.CompanyName}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactName}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactTitle}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.BestPhoneNumber}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.Email}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {getLocalTimeStampForRecording(
                          report?.TimeStampOfBooking,
                          'MM/DD/YYYY hh:mm A',
                        )}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {convertToUserLocalTime(
                          report?.DateBookedFor,
                          'MM/DD/YYYY',
                        )}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {/*{report?.TimeBookedFor ? moment(report?.TimeBookedFor).format('HH:mm:ss') : ""}*/}
                        {report?.TimeBookedFor}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactAddress}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.City}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.State}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ZipCode}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.LinkedInProfile}
                      </td>
                      <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.Website}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </ManagerLayout>
  );
};
