import { ErrorMessage } from '../ErrorMessage';

export const TextInput = ({
  label,
  placeholder,
  error,
  errorMessage,
  value,
  onChange,
}) => {
  const containerClassName = 'relative';
  const borderBottomColor = error
    ? 'border-b-red-500/20 focus:border-b-red-500'
    : 'border-b-secondary/20 focus:border-b-secondary';
  const inputClassName = `${borderBottomColor} peer block w-full border-b-2 border-x-transparent border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6`;
  const labelClassName =
    'pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500';
  const isErrorMessageVisible = error && !!errorMessage;

  return (
    <div>
      <div className={containerClassName}>
        <input
          value={value}
          onChange={onChange}
          className={inputClassName}
          placeholder={placeholder}
        />
        <label className={labelClassName}>{label}</label>
      </div>
      {isErrorMessageVisible && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  );
};
