import { Controller } from 'react-hook-form';

import { TextInput } from '../TextInput';

export const TextInputController = ({ control, name, ...textInputProps }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextInput
          value={value}
          onChange={onChange}
          error={Boolean(error)}
          errorMessage={error?.message}
          {...textInputProps}
        />
      )}
    />
  );
};
