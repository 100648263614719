import { CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { getTimezoneAbbreviation } from '../../../helpers/getTimezoneAbbreviation';
import BasicModal from '../../Common/BasicModel';
import { ErrorMessage } from '../../Shared';

const MIN_LENGTH = 1;
const MAX_LENGTH = 255;
const DEFAULT_ERROR_MESSAGE = 'Unexpected error';
const TABLE_COLUMNS = [
  {
    name: 'LeadId',
    selector: row => row.LeadId,
    width: 'fit-content',
  },
  {
    name: 'Contact Name',
    selector: row => row.ContactName,
    width: 'match-parent',
  },
  {
    name: 'Company Name',
    selector: row => row.CompanyName,
    width: 'match-parent',
  },
];

export const SearchModal = ({
  open,
  onClose,
  setSearchResults,
  searchResults,
  onFinish,
}) => {
  const abortControllerRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async nextSearch => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const response = await fetch('/call-options-search', {
        signal: abortControllerRef.current.signal,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          search: nextSearch,
          queryType: 'contactName',
          CallerTimeZone: getTimezoneAbbreviation(),
        }),
      });

      if (response.status === 400) {
        const invalidFields = await response.json();
        const nextErrorMessage =
          invalidFields?.validationErrors?.[0]?.search?.[0];
        setErrorMessage(nextErrorMessage ?? DEFAULT_ERROR_MESSAGE);
        setSearchResults([]);
        setIsSubmitting(false);
        return;
      }

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      setSearchResults(data.data);
      setIsSubmitting(false);
    } catch (e) {
      if (e?.name === 'AbortError') return;
      setIsSubmitting(false);
      setIsError(true);
      setSearchResults([]);
      toast.error('Something went wrong, please try again!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const onChangeSearchValue = e => {
    const nextSearch = e.target.value;
    setSearchValue(nextSearch);
    setIsError(false);

    if (nextSearch.length > MAX_LENGTH) {
      setIsSubmitting(false);
      setErrorMessage(ERROR_MESSAGES.MAX_LENGTH(MAX_LENGTH));
      setSearchResults([]);
      return;
    }

    if (nextSearch.length < MIN_LENGTH) {
      setIsSubmitting(false);
      setErrorMessage('');
      setSearchResults([]);
      return;
    }

    setErrorMessage('');
    setIsSubmitting(true);
    onSubmit(nextSearch);
  };

  const handleRowClicked = row => {
    document.cookie = `ID=${row.ID}`;
    onClose();
    onFinish();
  };

  const hasErrorMessage = !!errorMessage;
  const inputBorderColor = hasErrorMessage
    ? 'border-red-500/20 focus:border-red-500'
    : 'border-secondary/20 focus:border-blue-500';
  const inputClassName = `${inputBorderColor} block w-full rounded-lg border-2 px-4 py-3 text-lg font-medium transition focus:outline-none focus:ring-2 focus:ring-blue-500/20`;
  const isTableVisible =
    !!searchValue && !isSubmitting && !hasErrorMessage && !isError;

  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="search-modal"
        className="hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden"
      >
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <div className="px-2 pt-1 text-right">
              <button
                onClick={onClose}
                type="button"
                className="inline-flex h-7 w-7 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-overlay="#search-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-4 w-4 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            <div className="overflow-y-auto p-4">
              <input
                className={inputClassName}
                placeholder="Search by Contact Name"
                autoFocus={true}
                value={searchValue}
                onChange={onChangeSearchValue}
              />
              {hasErrorMessage && <ErrorMessage errorMessage={errorMessage} />}
              {isSubmitting && (
                <div className="flex justify-center items-center flex-1 mt-6">
                  <CircularProgress size={72} sx={{ color: '#1F68A2' }} />
                </div>
              )}
              {isTableVisible && (
                <div className="mt-6">
                  <DataTable
                    columns={TABLE_COLUMNS}
                    data={searchResults}
                    highlightOnHover
                    responsive
                    onRowClicked={handleRowClicked}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
