import React from 'react';
import moment from 'moment';
const SingleReport = ({ checked, report, handleExporting }) => {
    return (
        <tr className="border-b dark:border-neutral-500">
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        <input
                            id="selection-checkbox"
                            type="checkbox"
                            checked={checked}
                            onChange={(e) => {
                                    handleExporting(e.target.checked, report.LeadID);
                            }}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.LeadID}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.CallerID}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report.ApptType}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.CompanyName}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactName}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactTitle}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.BestPhoneNumber}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.Email}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {moment(report?.TimeStampOfBooking).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {moment(report?.DateBookedFor).format('YYYY-MM-DD')}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {/*{report?.TimeBookedFor ? moment(report?.TimeBookedFor).format('HH:mm:ss') : ""}*/}
                    {report?.TimeBookedFor}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ContactAddress}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.City}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.State}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.ZipCode}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.LinkedInProfile}
                </td>
                <td className="cursor-pointer whitespace-nowrap px-6 py-4">
                        {report?.Website}
                </td>
        </tr>
    );
}

export default SingleReport;