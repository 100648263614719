import BasicModal from '../../Common/BasicModel';

export const OptimizeScriptWithAIModal = ({ open, onClose, setTabIndex }) => {
  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="optimize-script-modal"
        className={`hs-overlay pointer-events-none z-[60] h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[50%] -translate-x-[50%]`}
      >
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto w-fit border-2 border-blue-900 border-solid rounded-xl border">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <div className="px-5">
              <div className="flex justfy-center p-3">
                <span className="font-bold text-lg">
                  Optimize your script with AI?
                </span>
              </div>
              <div className="flex items-center justify-center">
                <div
                  className="cursor-pointer flex min-w-24 items-center justify-center gap-x-1 rounded-full border border-accent-2 bg-transparent px-3 py-1 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 hover:border-accent-2 hover:bg-accent-2 hover:text-white transition-colors md:min-w-32 2xl:text-base mr-2 mb-5"
                  onClick={() => {
                    onClose();
                    setTabIndex(2);
                  }}
                >
                  <span>Let's do it!</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-yellow-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                    />
                  </svg>
                </div>
                <span
                  className="cursor-pointer flex min-w-24 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-2 py-1 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 hover:border-accent-2 hover:bg-accent-2 hover:text-white transition-colors md:min-w-32 md:px-4 md:py-2 2xl:text-base mr-2 mb-5"
                  onClick={onClose}
                >
                  Maybe later
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
