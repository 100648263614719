import { Button, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ManagerDashboardTabs } from '../../components/ManagerDashboardTabs';
import { DASHBOARD_STATE, INITIAL_DASHBOARD_TAB_DATA } from '../../constants';
import { getDashboardQueryBody, getDashboardState } from '../../helpers';
import { ManagerLayout } from '../../layouts';

export const ManagerDashboard = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabData, setTabData] = useState(INITIAL_DASHBOARD_TAB_DATA);
  const [tabTeamMembersData, setTabTeamMembersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const abortControllerRef = useRef(null);

  const fetchMDashboardData = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      const response = await fetch('/mdashboarddata', {
        signal: abortControllerRef.current.signal,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(getDashboardQueryBody(tabIndex)),
      });

      if (response.status !== 200) throw new Error();

      const data = await response.json();

      if (data.dashboardData.query1.length === 0) {
        setTabTeamMembersData([]);
      } else {
        setTabTeamMembersData(data.dashboardData.query1);
      }
      if (data.dashboardData.query2.length === 0) {
        setTabData(INITIAL_DASHBOARD_TAB_DATA);
      } else {
        let goalAchieved = 0;
        const combinedAppointmentGoal = data.dashboardData.query1.reduce(
          (total, member) => total + member.AppointmentGoal,
          0,
        );
        if (
          data.dashboardData.query1[0].AppointmentGoal !== 0 &&
          combinedAppointmentGoal !== 0
        ) {
          goalAchieved = Math.floor(
            (data.dashboardData.query2[0].FirstAppt / combinedAppointmentGoal) *
              100,
          );
        }
        setTabData({
          callback: data.dashboardData.query2[0].Callback,
          connectionRate: data.dashboardData.query2[0].ConnectionPerc,
          converstionRate: data.dashboardData.query2[0].ConversionRate,
          doNotCall: data.dashboardData.query2[0].DoNotCall,
          email: data.dashboardData.query2[0].Email,
          firstAppt: data.dashboardData.query2[0].FirstAppt,
          hungup: data.dashboardData.query2[0].HangUp,
          noAnswer: data.dashboardData.query2[0].NoAns,
          notInterested: data.dashboardData.query2[0].NotInterested,
          referral: data.dashboardData.query2[0].Referral,
          totalDials: data.dashboardData.query2[0].TotalDials,
          totalReaches: data.dashboardData.query2[0].TotalReaches,
          goalAchieved: goalAchieved,
        });
      }
      setIsLoading(false);
    } catch (e) {
      if (e?.name === 'AbortError') return;
      setIsLoading(false);
      setIsError(true);
      toast.error('Failed to load Dashboard data!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, [tabIndex]);

  useEffect(() => {
    fetchMDashboardData();
  }, [fetchMDashboardData]);

  const state = getDashboardState(isLoading, isError);

  return (
    <ManagerLayout>
      <div className="border-b border-gray-200">
        <nav
          className="flex space-x-4 lg:space-x-8"
          aria-label="Tabs"
          role="tablist"
        >
          <button
            type="button"
            className={`${tabIndex === 0 && 'active'} inline-flex items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-primary/40 transition hover:text-primary focus:text-secondary focus:outline-none disabled:pointer-events-none disabled:opacity-50 hs-tab-active:border-secondary hs-tab-active:text-secondary`}
            id="todays-stats-tab-btn"
            data-hs-tab="#todays-stats-tab"
            aria-controls="todays-stats-tab"
            onClick={() => setTabIndex(0)}
            role="tab"
          >
            Today's Stats
          </button>
          <button
            type="button"
            className={`${tabIndex === 1 && 'active'} inline-flex items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-primary/40 transition hover:text-primary focus:text-secondary focus:outline-none disabled:pointer-events-none disabled:opacity-50 hs-tab-active:border-secondary hs-tab-active:text-secondary`}
            id="this-week-tab-btn"
            data-hs-tab="#this-week-tab"
            aria-controls="this-week-tab"
            onClick={() => setTabIndex(1)}
            role="tab"
          >
            This Week
          </button>
          <button
            type="button"
            className={`${tabIndex === 2 && 'active'} inline-flex items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-primary/40 transition hover:text-primary focus:text-secondary focus:outline-none disabled:pointer-events-none disabled:opacity-50 hs-tab-active:border-secondary hs-tab-active:text-secondary`}
            id="this-month-tab-btn"
            data-hs-tab="#this-month-tab"
            aria-controls="this-month-tab"
            onClick={() => setTabIndex(2)}
            role="tab"
          >
            This Month
          </button>
        </nav>
      </div>
      <div className="flex-1 flex flex-col justify-center">
        {state === DASHBOARD_STATE.IS_LOADING && (
          <div className="flex justify-center items-center flex-1">
            <CircularProgress size={80} sx={{ color: '#1F68A2' }} />
          </div>
        )}
        {state === DASHBOARD_STATE.IS_ERROR && (
          <div className="flex justify-center items-center flex-1 flex-col gap-6">
            <div className="flex justify-center items-center flex-col gap-3">
              <span className="text-2xl font-medium">
                Failed to load Dashboard data
              </span>
              <span className="text-lg font-medium text-[#6b7280]">
                Error occurred while loading. Try again.
              </span>
            </div>
            <Button
              disableFocusRipple
              disableRipple
              variant="text"
              sx={{
                color: '#FF344C',
                fontFamily: 'inherit',
                textTransform: 'none',
                fontSize: 21,
                padding: 0,
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'transparent',
                  color: '#FF5A6D',
                },
              }}
              onClick={fetchMDashboardData}
            >
              Reload
            </Button>
          </div>
        )}
        {state === DASHBOARD_STATE.HAS_DATA && (
          <ManagerDashboardTabs
            tabData={tabData}
            tabTeamMembersData={tabTeamMembersData}
          />
        )}
      </div>
    </ManagerLayout>
  );
};
