import React, {useState, useEffect} from 'react'
import {toast, ToastContainer} from "react-toastify";
import Logo from '../../assets/header/login/img/logo.png';
import LogoTo from '../../assets/header/login/img/logo-2.png';

const ForgotPassword = ({}) => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false)
    const handleSendResetEmail = (event) => {
        event.preventDefault();
        fetch('/api/auth/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        }).then(response => {
            if (response.status === 200) {
                toast.info('Password reset email sent successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
                console.log('Password reset email sent successfully.');
                setEmailSent(true);
            } else if (response.status === 404) {
                toast.info('Email is not registered in our system.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
            } else {
                toast.info('Something went wrong.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });
            }
        })
    }
    useEffect(() => {
        document.title = 'Accelerate - Forgot Your Password?';
      }, []);

    return (
        <div>
            <ToastContainer/>
            <nav className="header" id="header">
                <div className="c-grid-fluid">
                    <div className="header__wrapper">
                        <div>
                            <div className="logo logo--header"><a class="logo__link" href="/"><img
                                className="logo__image" src={Logo}
                                alt="Logotype"/></a></div>
                        </div>
                    </div>
                </div>
            </nav>
            <main class="main main--start p-sign-in">
                <section class="sign" id="sign">
                    {!emailSent && (
                        <div class="c-grid">
                            <div class="section__wrapper">
                                <div class="c-section" data-align="center">
                                    <div class="c-section__head">
                                        <div class="logo logo--header"><a class="logo__link" href="/"><img
                                            class="logo__image" src={LogoTo}
                                            alt="Logotype"/></a></div>
                                    </div>
                                    <div class="c-section__body">
                                        <h5 class="c-section__title">Reset Your Password</h5>

                                        <form class="c-form" action="" method="" autocomplete="off" onSubmit={handleSendResetEmail}>
                                            <div class="c-form__field">
                                                <label class="c-form__label" for="email">Email</label>
                                                <input class="c-form__input" type="text" name="email" id="email"
                                                    placeholder="Enter your email address" value={email}
                                                    onChange={(e) => setEmail(e.target.value)} required/>
                                            </div>
                                            <div class="c-form__field c-form__field--btn">
                                                <div class="c-btn__wrapper">
                                                    <input class="c-btn" type={"submit"}
                                                        data-btn-color="blue"
                                                        data-btn-size="full" data-btn-fw="600"
                                                        data-btn-round="half" value={"Send"}/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {emailSent && (
                        <div class="c-grid">
                            <div class="section__wrapper">
                                <div class="c-section" data-align="center">
                                    <div class="c-section__head">
                                        <div class="logo logo--header"><a class="logo__link" href="/"><img
                                            class="logo__image" src={LogoTo}
                                            alt="Logotype"/></a></div>
                                    </div>
                                    <div class="c-section__body">
                                        <h5 class="c-section__title">An email has been sent to reset your password.</h5>
                                        <p class="p-2">Can't find the email? Try checking your spam folder or click <span onClick={handleSendResetEmail} className='text-secondary cursor-pointer'>resend</span> to try again.</p>
                                        <p class="p-2">Still having issues? Contact <a href="mailto:support@prospectiq.ai" className='text-secondary'>support@prospectiq.ai</a>.</p>
                                        <p class="p-2">Remember password? Try to <a href="/login" className='text-secondary'>login</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </main>
            <footer class="footer" id="footer">
                <div class="c-grid">
                    <div class="footer__wrapper"></div>
                </div>
            </footer>
        </div>
    )
}

export default ForgotPassword
