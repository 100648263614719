import Appointment from '../Common/Appointment';
import ConnectionRate from '../Common/ConnectionRate';
import CoversionRate from '../Common/CoversionRate';
import { DialOutcomes } from '../Common/DialOutcomes';
import { Dials } from '../Common/Dials';
import Reaches from '../Common/Reaches';
import { Team } from '../Common/Team';

export const ManagerDashboardTabs = ({ tabData, tabTeamMembersData }) => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4">
      <Appointment
        appointment={tabData.firstAppt}
        progress={tabData.goalAchieved}
      />
      <div className="grid gap-5">
        <Dials dials={tabData.totalDials} />
        <Reaches reaches={tabData.totalReaches} />
      </div>
      <div className="grid gap-5">
        <ConnectionRate
          connectionrate={Math.floor(tabData.connectionRate * 100)}
        />
        <CoversionRate
          conversionrate={Math.floor(tabData.converstionRate * 100)}
        />
      </div>
      <DialOutcomes
        appointment={tabData.firstAppt}
        hungup={tabData.hungup}
        callback={tabData.callback}
        notinterested={tabData.notInterested}
        email={tabData.email}
        noanswer={tabData.noAnswer}
        referral={tabData.referral}
        doNotCall={tabData.doNotCall}
      />
      <Team TeamMembers={tabTeamMembersData} />
    </div>
  );
};
