import React, {useState} from 'react'

const Practice = ({initiatePracticeCall, setPracticeCallDialed, practiceCallDialed}) => {
    const [isCentered, setIsCentered] = useState(false)
    setTimeout(() => setIsCentered(true), 0)

    return (
        <>
            <div className="rounded-xl m-auto round">
                <h6 className='text-center text-lg font-semibold py-3'>
                    What style do you want practice?
                </h6>
                <div className='items-center'>
                    <div className='text-center'>
                        <div class={`flex cursor-pointer items-center my-5 rounded-full transform transition-all duration-500 ease-in-out ${isCentered ? 'translate-x-0' : '-translate-x-full'}`} style={{backgroundColor: 'rgb(233, 232, 247)'}} onClick={()=>{
                            if (!practiceCallDialed) {
                                initiatePracticeCall('+17275940759');
                                setPracticeCallDialed(true);
                            }
                        }} data-hs-overlay="#dial-modal">
                            <img src="/avatar/practice-1.png" alt="img-agreeable" class="rounded-full h-16 w-16" />
                            <span class="text-lg px-10 font-bold text-blue-900"> Agreeable</span>
                        </div>
                        <div class={`flex cursor-pointer items-center my-5 rounded-full transform transition-all duration-500 ease-in-out ${isCentered ? 'translate-x-0' : 'translate-x-full'}`} style={{backgroundColor: 'rgb(222, 234, 245)'}} onClick={()=>{
                            if (!practiceCallDialed) {
                                initiatePracticeCall('+17275940837');
                                setPracticeCallDialed(true);
                            }
                        }} data-hs-overlay="#dial-modal">
                            <img src="/avatar/practice-2.png" alt="img-agreeable" class="rounded-full h-16 w-16" />
                            <span class="text-lg px-10 font-bold text-blue-900"> Skeptical</span>
                        </div>
                        <div class={`flex cursor-pointer items-center my-5 rounded-full transform transition-all duration-500 ease-in-out ${isCentered ? 'translate-x-0' : '-translate-x-full'}`} style={{backgroundColor: 'rgb(239, 239, 227)'}} onClick={()=>{
                            if (!practiceCallDialed) {
                                initiatePracticeCall('+17275940765');
                                setPracticeCallDialed(true);
                            }

                        }} data-hs-overlay="#dial-modal">
                            <img src="/avatar/practice-3.png" alt="img-agreeable" class="rounded-full h-16 w-16" />
                            <span class="text-lg px-10 font-bold text-blue-900"> Difficult</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Practice
