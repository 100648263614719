import React, {useState} from "react";

const WhatHappened = ({ setIndex, setHappenedIndex, happenedIndex, updateDialoutcomes, handleOpen3, handleFinish }) => {
    const [finishClicked, setFinishClicked] = useState(false);
    const [happenedContent, setHappenedContent] = useState("");
    const [isPositioned, setIsPositioned] = useState(false)
    setTimeout(() => setIsPositioned(true), 1000)

    return (
        <>
            <div class="flex h-full flex-col justify-between gap-4">
                <div
                    class="h-full max-h-[450px] overflow-y-auto overflow-x-hidden rounded-2xl bg-white/30 p-6 2xl:p-16 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
                    <h4 class="text-center text-lg font-semibold xl:text-xl">
                        What happened on your call?
                    </h4>

                    <div class="mt-8 flex flex-wrap items-center justify-center gap-4">
                        <a
                            onClick={() => { setIndex(2); setHappenedIndex(0) }}
                            style={{
                                transition: `transform ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-out, opacity ${1.5 - 0 * 0.1}s ${0 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Booked an appointment
                        </a>
                        <a
                            onClick={() => { setIndex(2); setHappenedIndex(1) }}
                            style={{
                                transition: `transform ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-out, opacity ${1.5 - 1 * 0.1}s ${1 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Scheduled a callback
                        </a>
                        <a
                            onClick={() => { setIndex(7); setHappenedIndex(2) }}
                            style={{
                                transition: `transform ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-out, opacity ${1.5 - 2 * 0.1}s ${2 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#5BE7CD] hover:bg-[#5BE7CD] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Send an email
                        </a>
                    </div>

                    <div class="mt-12 flex flex-wrap items-center justify-center gap-4">
                        <a
                            onClick={() => { setHappenedContent("Hung up"); setHappenedIndex(4) }}
                            style={{
                                transition: `transform ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-out, opacity ${1.5 - 3 * 0.1}s ${3 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} ${happenedContent === "Hung up" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""}`}>
                            Hung up
                        </a>
                        <a
                            onClick={() => { setIndex(16); setHappenedIndex(5) }}
                            style={{
                                transition: `transform ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-out, opacity ${1.5 - 4 * 0.1}s ${4 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Not interested
                        </a>
                    </div>

                    <div class="mt-4 flex flex-wrap items-center justify-center gap-4">
                        <a
                            onClick={() => { setHappenedContent("No answer"); setHappenedIndex(6) }}
                            style={{
                                transition: `transform ${1.5 - 5 * 0.1}s ${5 * 0.2}s ease-out, opacity ${1.5 - 5 * 0.1}s ${5 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} ${happenedContent === "No answer" ? "ring ring-primary/20 border-accent-2 bg-accent-2" : ""}`}>
                            No answer
                        </a>
                        <a
                            onClick={() => { setIndex(18); setHappenedIndex(8) }}
                            style={{
                                transition: `transform ${1.5 - 6 * 0.1}s ${6 * 0.2}s ease-out, opacity ${1.5 - 6 * 0.1}s ${6 * 0.2}s ease-in-out`,
                            }}
                            class={`cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-4 py-2 text-center font-medium text-accent-2 transition hover:border-[#F55F6E] hover:bg-[#F55F6E] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 2xl:px-6 transition-all duration-1000 ${isPositioned ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Do Not Call
                        </a>
                    </div>
                </div>
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <a
                        onClick={() => setIndex(0)}
                        class="cursor-pointer inline-flex items-center gap-x-2 text-sm text-gray-800 transition hover:text-secondary">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            class="h-4 w-4"
                            viewBox="0 0 24 24">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                        Go Back
                    </a>
                    { (happenedIndex === 4 || happenedIndex === 6) && (
                        <button
                            onClick={() => { 
                                updateDialoutcomes(happenedIndex);
                                setFinishClicked(true);
                                handleFinish();
                            }}
                            class="cursor-pointer inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg"
                            disabled={finishClicked}>
                            Finish
                        </button>
                    )}
                    <span
                        onClick={handleOpen3}
                        data-hs-overlay="#call-notes-modal"
                        className="cursor-pointer rounded-lg bg-transparent-1 p-1 text-primary">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="h-6 w-6"
                        >
                            <path
                                d="M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z" />
                        </svg>
                    </span>
                </div>
            </div>
        </>
    );
};

export default WhatHappened;
