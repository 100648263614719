import { ButtonCallNotes, ButtonFinish, ButtonGoBack } from '../../Shared';

export const AppointmentInformation = ({
  timeInfo,
  pickerInfo,
  appointment,
  finishClicked,
  formCallNotes,
  formObjContact,
  setIndex,
  getUserTimeZone,
  setFinishClicked,
  formatPhoneNumber,
  updateDialOutcomes,
  setIsFindLeadLoading,
  setIsCallOptionsLoading,
  handleOpenCallNotesModal,
}) => {
  return (
    <div className="mt-0 h-justify-around">
      <form action="" className="px-4">
        <div className="flex items-center justify-end">
          <a
            href={`mailto:${formObjContact.email}`}
            name={'email'}
            className="flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-5 w-5"
            >
              <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
              <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
            </svg>
          </a>
          <a
            href={`${formObjContact.linkedin}`}
            target="_blank"
            name={'linkedin'}
            className="flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path d="M7.5 5A2.518 2.518 0 0 0 5 7.5v17C5 25.867 6.133 27 7.5 27h17c1.367 0 2.5-1.133 2.5-2.5v-17C27 6.133 25.867 5 24.5 5Zm0 2h17c.285 0 .5.215.5.5v17a.49.49 0 0 1-.5.5h-17a.489.489 0 0 1-.5-.5v-17c0-.285.215-.5.5-.5Zm2.938 1.719a1.719 1.719 0 1 0 0 3.437 1.719 1.719 0 0 0 0-3.437Zm9.03 4.562c-1.433 0-2.386.785-2.78 1.531h-.063V13.5h-2.813V23h2.938v-4.688c0-1.238.246-2.437 1.781-2.437 1.512 0 1.532 1.398 1.532 2.5V23H23v-5.219c0-2.554-.543-4.5-3.531-4.5ZM9 13.5V23h2.969v-9.5Z" />
            </svg>
          </a>
          <a
            href={`${formObjContact.website}`}
            target="_blank"
            name={'website'}
            className="flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-5 w-5"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20M2 12h20" />
            </svg>
          </a>
        </div>
        <div className="space-y-4">
          <div className="relative">
            <input
              type="text"
              id="contact-name"
              name="contact"
              className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
              placeholder="Contact Name"
              value={formObjContact.contact}
              readOnly
            />
            <label
              htmlFor="contact-name"
              className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              Contact Name
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="title"
              name="title"
              className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
              placeholder="Title"
              value={formObjContact.title}
              readOnly
            />
            <label
              htmlFor="title"
              className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              Title
            </label>
          </div>
          <div className="grid grid-cols-1 gap-0 h-gap-3 xl:grid-cols-1">
            <div className="relative">
              <input
                type="text"
                id="company-name"
                name="company"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Company Name"
                value={formObjContact.company}
                readOnly
              />
              <label
                htmlFor="company-name"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Company Name
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="industry"
                name="industry"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Industry"
                value={formObjContact.industry}
                readOnly
              />
              <label
                htmlFor="industry"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Industry
              </label>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-2 xl:grid-cols-2">
            <div className="relative">
              <input
                type="text"
                id="bestphoneinput"
                name="time"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Best Phone"
                value={formatPhoneNumber(formObjContact.bestPhone)}
                readOnly
              />
              <label
                htmlFor="bestphoneinput"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Best Phone
              </label>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 md:grid-cols-3 xl:grid-cols-3">
            <div className="relative">
              <input
                type="text"
                id="apptDateinput"
                name="time"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Appt Date"
                value={pickerInfo.format('MM/DD/YYYY')}
                readOnly
              />
              <label
                htmlFor="apptDateinput"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Appt Date
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="apptTimeInput"
                name="time"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Appt Time"
                value={`${timeInfo} ${getUserTimeZone()}`}
                readOnly
              />
              <label
                htmlFor="apptTimeInput"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Appt Time
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="apptTypeInput"
                name="time"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Appointment Type"
                value={appointment}
                readOnly
              />
              <label
                htmlFor="apptTypeInput"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Appointment Type
              </label>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-5 2xl:mb-3 flex flex-wrap items-center justify-between gap-2">
        <ButtonGoBack
          onClick={() => {
            setIndex(4);
          }}
        />
        <ButtonFinish
          onClick={() => {
            updateDialOutcomes(0);
            setFinishClicked(true);
            setIsFindLeadLoading(true);
            setIsCallOptionsLoading(true);
          }}
          disabled={finishClicked}
        />
        <ButtonCallNotes
          onClick={handleOpenCallNotesModal}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </div>
  );
};
