import { CircularProgress } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useUserRoleContext } from '../hooks';
import {
  CallerDashboard,
  CallerStats,
  CallProcessing,
  ForgotPassword,
  Login,
  ManagerDashboard,
  Register,
  Report,
  ResetPassword,
} from '../pages';
import { RootProvider } from '../providers';

const ROUTES_BY_USER_ROLE_MAP = {
  0: (
    <>
      <Route path="/caller-dashboard" element={<CallerDashboard />} />
      <Route path="/call-processing" element={<CallProcessing />} />
    </>
  ),
  1: (
    <>
      <Route path="/mdashboard" element={<ManagerDashboard />} />
      <Route path="/caller-stats" element={<CallerStats />} />
      <Route path="/reports" element={<Report />} />
    </>
  ),
};

export const Router = () => {
  const { isUserRoleLoading, hasUserRole, userRole } = useUserRoleContext();

  if (isUserRoleLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress size={120} sx={{ color: '#FFFFFF' }} />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {hasUserRole && (
        <Route element={<RootProvider />}>
          {ROUTES_BY_USER_ROLE_MAP[userRole]}
        </Route>
      )}
    </Routes>
  );
};
