import Cookies from 'js-cookie';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RefreshTokenContext } from '../context';

const fetchRefreshToken = () => fetch('/refresh-token', { method: 'POST' });

const ONE_MIN_IN_MS = 60 * 1000;
const TEN_MIN_IN_MS = 10 * ONE_MIN_IN_MS;

export const RefreshTokenProvider = ({ children }) => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const refreshTokenSubscriptionRef = useRef(null);

  const resetRefreshTokenSubscription = () => {
    if (!refreshTokenSubscriptionRef.current) return;
    clearTimeout(refreshTokenSubscriptionRef.current);
    refreshTokenSubscriptionRef.current = null;
  };

  const handleRefreshTokenSubscription = useCallback(async () => {
    try {
      const response = await fetchRefreshToken();

      if (response.status === 401) {
        resetRefreshTokenSubscription();
        navigateRef.current('/login');
        toast.info('Failed to refresh token. Automatic logout', {
          position: 'top-right',
          autoClose: 3000,
        });
        return;
      }

      if (response.status !== 200) throw new Error();

      const currentTimeInMs = moment().valueOf();
      const tokenExpiresAtInMs = Cookies.get('tokenExpiresAt');
      const timeUntilExpirationInMs =
        Number(tokenExpiresAtInMs) - currentTimeInMs;
      const refreshTimeInMs = timeUntilExpirationInMs - TEN_MIN_IN_MS;

      refreshTokenSubscriptionRef.current = setTimeout(() => {
        handleRefreshTokenSubscription();
      }, refreshTimeInMs);
    } catch {
      refreshTokenSubscriptionRef.current = setTimeout(() => {
        handleRefreshTokenSubscription();
      }, ONE_MIN_IN_MS);
    }
  }, []);

  useEffect(() => {
    handleRefreshTokenSubscription();

    return resetRefreshTokenSubscription;
  }, [handleRefreshTokenSubscription]);

  return (
    <RefreshTokenContext.Provider value={{}}>
      {children}
    </RefreshTokenContext.Provider>
  );
};
