import { Button, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ScriptTab from '../../CallProcessing/ScriptTab';
import BasicModal from '../../Common/BasicModel';

const STATE = {
  HAS_DATA: 'hasData',
  IS_LOADING: 'isLoading',
  IS_ERROR: 'isError',
};

const getState = (isLoading, isError) => {
  if (isLoading) return STATE.IS_LOADING;
  if (isError) return STATE.IS_ERROR;
  return STATE.HAS_DATA;
};

export const PointerStatsModal = ({ open, onClose, prompt }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchGhatGPT = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await fetch('/api/v1/chatgpt', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: prompt }),
      });

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      setMessage(data.answer);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setIsError(true);
      toast.error('Failed to load message!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, [prompt]);

  useEffect(() => {
    fetchGhatGPT();
  }, [fetchGhatGPT]);

  const state = getState(isLoading, isError);

  return (
    <BasicModal open={open} handleClose={onClose}>
      <div className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]">
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <button
              onClick={onClose}
              className="inline-flex justify-end items-center border-transparent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
            <div className="overflow-y-auto px-5 pb-5">
              {state === STATE.IS_LOADING && (
                <div className="flex justify-center items-center">
                  <CircularProgress size={80} sx={{ color: '#1F68A2' }} />
                </div>
              )}
              {state === STATE.IS_ERROR && (
                <div className="flex justify-center items-center flex-col gap-6">
                  <div className="flex justify-center items-center flex-col gap-3">
                    <span className="text-2xl font-medium">
                      Failed to load message
                    </span>
                    <span className="text-lg font-medium text-[#6b7280]">
                      Error occurred while loading. Try again.
                    </span>
                  </div>
                  <Button
                    disableFocusRipple
                    disableRipple
                    variant="text"
                    sx={{
                      color: '#FF344C',
                      fontFamily: 'inherit',
                      textTransform: 'none',
                      fontSize: 21,
                      padding: 0,
                      '&.MuiButtonBase-root:hover': {
                        backgroundColor: 'transparent',
                        color: '#FF5A6D',
                      },
                    }}
                    onClick={fetchGhatGPT}
                  >
                    Reload
                  </Button>
                </div>
              )}
              {state === STATE.HAS_DATA && <ScriptTab words={message} />}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
