import React, { useEffect, useMemo } from 'react';
import { useTypeWriter } from '../../../hooks/useTypeWriter';

const ScriptTab = (props) => {
    // Use useMemo to ensure content is re-evaluated only when props.words changes
    const content = useMemo(() => `
        <span class="whitespace-preline">
            ${props.words}
        </span>
    `, [props.words]);

    // Use the custom hook with updated content
    const { displayResponse } = useTypeWriter(content, true);

    return (
        <p
            id="typed"
            className="text-base font-medium lg:text-lg"
            dangerouslySetInnerHTML={{ __html: displayResponse }}
        ></p>
    );
};

export default ScriptTab;
