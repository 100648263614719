import BasicModal from '../../Common/BasicModel';
import AudioPlayer from '../../ConversionLab/AudioPlayer';

export const ListenRecordingModal = ({
  open,
  onClose,
  selectedRecording,
  getLocalTimeStampForRecording,
}) => {
  return (
    <BasicModal open={open} handleClose={onClose}>
      <div
        id="recording-playback-modal"
        className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
      >
        <div className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
          <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
            <div className="flex w-full justify-between">
              <div className="flex">
                <span className="inline-flex justify-start items-center border-transparent">
                  <svg
                    data-slot="icon"
                    fill="none"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                    ></path>
                  </svg>
                </span>
                <span className="p-2">{selectedRecording.ContactName}</span>
                <span className="p-2">
                  {getLocalTimeStampForRecording(
                    selectedRecording.TimeStamp,
                    'MM-DD-YYYY hh:mm:ss A',
                  )}
                </span>
              </div>
              <button
                onClick={onClose}
                className="inline-flex justify-end items-center border-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
            <AudioPlayer src={selectedRecording.RecordingURL} />
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
